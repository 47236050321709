var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            temporary:
              _vm.$vuetify.breakpoint[_vm.$store.getters.cockpitTemporarySize],
            clipped: "",
            app: ""
          },
          model: {
            value: _vm.showCockpit,
            callback: function($$v) {
              _vm.showCockpit = $$v
            },
            expression: "showCockpit"
          }
        },
        [
          _c(
            "v-list",
            { staticClass: "pa-1" },
            [
              _c(
                "v-list-item",
                { attrs: { tag: "div" } },
                [
                  _c(
                    "v-list-item-avatar",
                    [_c("v-icon", [_vm._v("explore")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Navigation")])],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndDown
                    ? _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return function() {
                                    return _vm.$store.commit(
                                      "SET_COCKPIT_STATE",
                                      false
                                    )
                                  }.apply(null, arguments)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("chevron_left")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("router-view", { attrs: { name: "cockpitContainer" } }),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$router.push("/")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("cancel")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Zurück zur Startseite")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "600px"
          },
          model: {
            value: _vm.cacheDeletionInProgress,
            callback: function($$v) {
              _vm.cacheDeletionInProgress = $$v
            },
            expression: "cacheDeletionInProgress"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Cache wird gelöscht")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }