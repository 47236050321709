<template>
<v-row>
  <v-col cols="12" sm="10">
    <v-text-field
      v-model="searchTokenModel"
      outlined
      label="Suche"
      type="text"
      >
      <template v-slot:append>
        <v-chip right color="primary">
          {{ filteredList.length }}
        </v-chip>
      </template>
    </v-text-field>
    <v-list rounded>
      <v-lazy
        v-model="isActive"
        v-for="(item,index) in visibleItems"
        :key="item.id"
        >
        <v-list-item
          :class="index % 2 == 0 ? 'white' : 'teal lighten-5'"
          >
          <v-list-item-icon>
            <v-avatar>
              {{ item.id }}
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ item.name }} </v-list-item-title>
            <v-list-item-subtitle> {{ item.name_alt }} </v-list-item-subtitle>
            <div
              class="d-flex flex-row flex-wrap"
              >
              <div
                v-for="pic in item.media_list"
                :key="pic.id"
                class="d-flex flex-row pa-2"
                >
                <v-badge
                  avatar
                  left
                  overlap
                  >
                  <template v-slot:badge>
                    <v-avatar>
                      {{ pic.id }}
                    </v-avatar>
                  </template>
                  <v-avatar>
                    <img
                      :src="`${$store.getters.appState.apihost}/media/${pic.url}`"
                      >
                  </v-avatar>
                </v-badge>
                <div
                  class="d-flex flex-column ml-2"
                  >
                  <div class="text-subtitle-1"> {{ pic.name }} </div>
                  <div class="text-body-2"> {{ pic.beschreibung }} </div>
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-lazy>
    </v-list>
    <v-pagination
      v-model="page"
      :length="Math.ceil(filteredList.length / perPage)"
      >
    </v-pagination>
  </v-col>
</v-row>
</template>
<script>

export default {
  data: () => ({
    isActive: false,
    page: 1,
    perPage: 20
  }),
  computed: {
    filteredList () {
      return this.$store.getters.filteredElementList(
        this.$store.getters.elementList
      )
    },
    visibleItems () {
      return this.filteredList.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    },
    searchTokenModel: {
      get () {
        return this.$store.getters.activeElementSearchToken
      },
      set (searchToken) {
        this.$store.commit('SET_ELEMENT_FILTER', searchToken)
      }
    }
  }
}
</script>
