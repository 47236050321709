<template>
  <div>
    <v-card text>
      <v-system-bar color="secondary" />
      <v-app-bar
        dark
        clipped-left
        color="primary"
        height="150"
        extension-height="37"
      >
        <template v-slot:extension>
          <v-row>
            <v-col offset-md="1">
              <p
                class="text--primary"
                @click.stop="deleteSWCache()"
              >
                Tutorial Anatomie
              </p>
            </v-col>
          </v-row>
        </template>
        <template v-slot:default>
          <v-row>
            <v-col offset-md="1">
              <a href="https://www.unibas.ch" target="_blank">
                <img
                  style="max-height: 236px;"
                  src="/static/img/uni-basel-logo.svg"
                  alt="Logo der Universität Basel"
                >
              </a>
            </v-col>
          </v-row>
        </template>
      </v-app-bar>
    </v-card>
    <v-dialog
      v-model="cacheDeletionInProgress"
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card tile>
        <v-card-title>
          <span class="headline">Cache wird gelöscht</span>
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
  .v-toolbar__extension {
    background-color: #d2ebe8;
  }
</style>
<script>
export default {
  data () {
    return {
      cacheDeletionInProgress: false
    }
  },
  methods: {
    deleteSWCache () {
      this.cacheDeletionInProgress = true
      new Promise(function (resolve, reject) {
        caches.keys().then(function (keyList) {
          keyList.map(function (key) {
            return Promise.all(keyList.map(function (key) {
              resolve(caches.delete(key))
            }))
          })
        })
        resolve([])
      }).then(() => {
        window.location.reload()
      }).finally(() => {
        this.cacheDeletionInProgress = false
      })
    }
  }
}
</script>
