var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("router-view", { attrs: { name: "metabalken" } }),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "cockpit" } }),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "rightDrawer" } }),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height" },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "bottomNavigation" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }