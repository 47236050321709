var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-space prep-card" },
    [
      _c("v-progress-linear", {
        staticClass: "my-0",
        attrs: {
          value: _vm.percentage,
          color: _vm.timerColor,
          "background-color": "teal lighten-3",
          height: "5px"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card-title-bar" }, [
        _vm.thisQuestion
          ? _c("span", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.thisQuestion.question_text) + " ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "help" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("chart", {
            attrs: {
              url: _vm.url,
              "pin-list": _vm.pinList,
              "hide-labeling": true
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }