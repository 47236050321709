var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "text-center pt-10" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 270,
                size: 100,
                width: 15,
                value: _vm.percentage,
                color: "teal"
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.indexCurrent) +
                  "/" +
                  _vm._s(_vm.total) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.skip(-_vm.total / 10)
                }
              }
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("\n        fast_rewind\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.skip(-1)
                }
              }
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("\n        skip_previous\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.skip(1)
                }
              }
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("\n        skip_next\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.skip(_vm.total / 10)
                }
              }
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("\n        fast_forward\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-1", attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          clearable: "",
                          items: _vm.evaluatedIfAvailable("bildTypFilter"),
                          label: "Bildtyp Filter",
                          multiple: "",
                          "prepend-icon": "filter_list",
                          solo: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-chip",
                                  { attrs: { "input-value": data.selected } },
                                  [
                                    _c("strong", [
                                      _vm._v(_vm._s(data.item.name))
                                    ])
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-chip",
                                  { attrs: { disabled: data.item.disabled } },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { left: "", color: "primary" } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.preCountItems(
                                                "bildTypFilter",
                                                data.item.name
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(data.item.name) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.bildTypFilterModel,
                          callback: function($$v) {
                            _vm.bildTypFilterModel = $$v
                          },
                          expression: "bildTypFilterModel"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm
                    .evaluatedIfAvailable("structureFilter")
                    .reduce(function(accu, param) {
                      return accu || !param.disabled
                    }, false)
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              clearable: "",
                              items: _vm.evaluatedIfAvailable(
                                "structureFilter"
                              ),
                              label: "Struktur Filter",
                              multiple: "",
                              "prepend-icon": "filter_list",
                              solo: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            "input-value": data.selected
                                          }
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(data.item.name))
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            disabled: data.item.disabled
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.preCountItems(
                                                      "structureFilter",
                                                      data.item.name
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(data.item.name) +
                                              "\n              "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3855274427
                            ),
                            model: {
                              value: _vm.structureFilterModel,
                              callback: function($$v) {
                                _vm.structureFilterModel = $$v
                              },
                              expression: "structureFilterModel"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }