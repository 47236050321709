<template>
  <div>
    <div class="text-center">
      <div
        v-if="Object.keys($store.getters[challengeMode]).length === 0 || $store.getters[challengeMode].score.questionList.length === 0"
      >
        <v-btn
          color="primary darken-2"
          text-color="white"
          @click.native="$createChallenge($route.params.topic, challengeMode, $store, $router)"
        >
          Challenge starten
        </v-btn>
      </div>
      <div v-else-if="$store.getters[challengeMode].score.questionList.length < $store.getters[challengeMode].questionAmount">
        <v-btn
          color="primary darken-2"
          text-color="white"
          @click.native="continueChallenge()"
        >
          Challenge fortsetzen
        </v-btn>
      </div>
      <div v-else-if="$store.getters[challengeMode].score.questionList.length <= $store.getters[challengeMode].questionAmount">
        <v-btn
          color="primary darken-2"
          text-color="white"
          @click.native="$createChallenge($route.params.topic, challengeMode, $store, $router)"
        >
          Neue Challenge starten
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    challengeMode: {
      type: String,
      default: 'simulation'
    }
  },
  methods: {
    continueChallenge () {
      this.$router.push({
        name: this.challengeMode,
        params: {
          index: `${this.$store.getters[this.challengeMode].score.questionList.length}`
        }
      })
    }
  }
}
</script>
