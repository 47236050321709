var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-container",
            { staticClass: "justify", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.topicList, function(topic) {
                  return _c("TopicCard", {
                    key: topic.name,
                    attrs: { topic: topic }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }