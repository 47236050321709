<template>
  <div id="map" />
</template>

<style>
#map {
    height: 100%;
    width: 100%;
    background-color: #0000;
}
.pin-label {
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    color: white;
}
.leaflet-top.leaflet-left {
    /* put zoom control behind vuejs side drawer */
    z-index: 1;
}
.leaflet-control-attribution.leaflet-control {
    /* make leaflet label less ugly */
    background-color: #0000;
}
.leaflet-pane {
    /* hide map behind vue side drawer overlay */
    z-index: 0;
}
</style>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  props: {
    pinList: {
      type: Array,
      default () {
        return []
      }
    },
    url: {
      type: String,
      default () {
        return ''
      }
    },
    hideLabeling: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data: function () {
    return {
      map: undefined,
      resizeObserver: null
    }
  },
  watch: {
    url (oldUrl, newUrl) {
      this.setupLeafletMap()
    }
  },
  mounted () {
    this.resizeObserver = new ResizeObserver(this.onResize)
    this.resizeObserver.observe(document.getElementById('map'))
    this.setupLeafletMap()
  },
  created () {
    window.addEventListener('resize', this.resizeEvent)
  },
  methods: {
    onResize () {
      this.setupLeafletMap()
    },
    handlePinClick (index) {
      this.$store.commit('TOGGLE_ELEMENT_LABEL_VISIBILITY', index)
    },
    setupLeafletMap () {
      const img = new Image()
      img.src = this.url
      img.onload = () => {
        if (this.map !== undefined) {
          this.map.off()
          this.map.remove()
        }
        this.map = L.map('map', {
          crs: L.CRS.Simple,
          minZoom: 0,
          maxZoom: 2
        })
        // map image to map container size
        // get map container dimensions and calculate ratio
        const mapX = this.map.getSize().x
        const mapY = this.map.getSize().y
        const mapRatio = mapX / mapY
        // calc img ratio
        const imgRatio = img.naturalWidth / img.naturalHeight
        const factor = (mapRatio < imgRatio) ? mapX / img.naturalWidth : mapY / img.naturalHeight
        const yMultiplier = (mapRatio < imgRatio) ? img.naturalHeight * factor : mapY
        const xMultiplier = (mapRatio < imgRatio) ? mapX : img.naturalWidth * factor
        const bounds = [[0, 0], [yMultiplier, xMultiplier]]
        L.imageOverlay(this.url, bounds).addTo(this.map)
        this.map.setMaxBounds(bounds)
        this.map.setView([0, 0], 0)
        this.pinList.map((p, index) => {
          const pinCoord = p.position.split(',')
          const pinTextCoord = p.position_text.split(',')
          L.polyline(
            [
              [(-pinCoord[1] + 1) * yMultiplier, pinCoord[0] * xMultiplier],
              [(-pinTextCoord[1] + 1) * yMultiplier, pinTextCoord[0] * xMultiplier]
            ],
            { color: '#587a77' }
          ).addTo(this.map)
          const pinNummer = this.hideLabeling ? '&nbsp;' : index + 1
          const m = L.marker([(-pinTextCoord[1] + 1) * yMultiplier, pinTextCoord[0] * xMultiplier], {
            icon: L.divIcon({
              className: 'label',
              html: `<div class="pin-label primary darken-2"><b>${pinNummer}</b></div>`,
              iconSize: 25
            }),
            zIndexOffset: 99999
          })
          m.on('click', (e) => this.handlePinClick(index))
          m.addTo(this.map)
        })
      }
    }
  }
}
</script>
