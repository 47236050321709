<template>
  <v-app>
    <router-view name="metabalken" />
    <router-view name="cockpit" />
    <router-view name="rightDrawer" />
    <v-main>
      <v-container class="fill-height">
        <router-view />
      </v-container>
    </v-main>
    <!-- <router-view name="statusbar" /> -->
    <router-view name="bottomNavigation" />
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>
