<template>
  <v-row>
    <v-col cols="12">
      <h1> Prüfungssimulation </h1>
      <p>Anzahl Fragen: {{ $store.getters.simulation.questionAmount }} </p>
      <p>Zeit pro Frage in Sekunden: {{ $store.getters.simulation.time }} </p>
      <div
        v-if="Object.keys($store.getters.simulation).length === 0 || $store.getters.simulation.score.questionList.length === 0"
      >
        <v-btn
          color="primary darken-2"
          text-color="white"
          @click.native="$createChallenge($route.params.topic, challengeMode, $store, $router)"
        >
          Challenge starten
        </v-btn>
      </div>
      <div v-else-if="$store.getters.simulation.score.questionList.length < $store.getters.simulation.questionAmount">
        <p>anzahl beantworteter fragen: {{ $store.getters.simulation.score.questionList.length }} </p>
      </div>
      <div v-else-if="$store.getters['simulation'].score.questionList.length = $store.getters.simulation.questionAmount">
        <h3> {{ resultMessage }} </h3>
        <v-expansion-panels popout>
          <Result
            v-for="(q,i) in $store.getters.simulation.score.questionList"
            :i="i"
            :key="i"
            :q="q" />
        </v-expansion-panels>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Result from './Result.vue'
export default {
  components: {
    Result
  },
  props: {
    challengeMode: {
      type: String,
      default: 'simulation'
    }
  },
  computed: {
    resultMessage () {
      var simu = this.$store.getters.simulation
      var primScore = simu.score.questionList.reduce((accu, q) => accu + q.score.primary, 0)
      var secuScore = simu.score.questionList.reduce((accu, q) => accu + q.score.secondary, 0)
      var score = primScore + secuScore
      var message = ''
      var punkt = score === 1 ? 'Punkt' : 'Punkte'
      if (score > 35) {
        message = `Applaus, fantastisch, hervorragend! Du hast ${score} ${punkt}!`
      } else if (score > 30) {
        message = `Sehr gut, Du hast ${score} ${punkt}!`
      } else if (score > 20) {
        message = `Nicht schlecht, Du hast ${score} ${punkt}!`
      } else {
        message = `Du hast ${score} ${punkt}.`
      }
      return message
    }
  }
}
</script>
