<template>
  <v-navigation-drawer
    v-model="showRightDrawer"
    :temporary="$vuetify.breakpoint[$store.getters.rightDrawerTemporarySize]"
    :permanent="showPermanently"
    clipped
    right
    app
  >
    <router-view name="rightDrawerContent" />
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    showRightDrawer: {
      set: function (value) {
        this.$store.commit('SET_RIGHT_DRAWER_STATE', value)
      },
      get: function () {
        return this.$store.getters.showRightDrawer
      }
    },
    showPermanently () {
      const show = this.$vuetify.breakpoint[this.$store.getters.rightDrawerPermanentSize] && this.$route.name !== 'simulation-status'
      return show
    }
  },
  created () { // hide on small size
    this.$store.commit('SET_RIGHT_DRAWER_STATE', false)
  }
}
</script>
