<template>
  <v-container class="my-0 py-5 px-5">
    <v-row
      v-if="questionAnswerScoreList.length === 0"
    >
      <v-col class="my-0 py-0 px-3" cols="12" sm="6" md="3">
        Keine Antwort
      </v-col>
    </v-row>
    <div
      v-for="(textResult, i) in questionAnswerScoreList"
      :key="i"
    >
      <v-row>
        <v-container>
          <v-row>
            <v-col class="px-3 my-0 py-0" cols="12" sm="6">
              <v-text-field
                :value="textResult.ga"
                :success="textResult.levVal < 10"
                :error="textResult.levVal >= 10"
                readonly
                hint="Ihre Antwort"
                persistent-hint
              />
            </v-col>
            <v-col class="my-0 py-0" cols="12" sm="6">
              <v-text-field
                :value="textResult.ca"
                success
                readonly
                hint="Richtige Antwort"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </div>
    <v-row v-if="getOtherCorrectAnswerList.length > 0"
           class="x12 pt-3"
    >
      <v-container>
        <v-row x12 class="my-1">
          <v-col class="py-0">
            Korrekte
            <span>Antwort</span><span v-if="getOtherCorrectAnswerList.length > 1">en</span><span>:</span>
          </v-col>
        </v-row>
        <v-row class="my-1">
          <v-col v-for="(ans, i) in getOtherCorrectAnswerList"
                 :key="i"
                 class="px-3 py-0"
                 cols="12" sm="6" md="4"
          >
            {{ ans }}
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import levenshtein from 'js-levenshtein'

export default {
  props: {
    questionObject: {
      type: Object,
      default () { return {} }
    },
    answerGiven: {
      type: Array,
      default () { return [] }
    },
    rank: {
      type: String,
      default () { return 'primary' }
    },
    index: {
      type: Number,
      default () { return 0 }
    }
  },
  computed: {
    questionAnswerScoreList () {
      return this.calculateScore(
        this.answerGiven, this.questionObject.answers
      )
    },
    // return all correct answer that cannot be found in given answers
    getOtherCorrectAnswerList () {
      // list of answers that match a given answer
      var caList = this.listOfCorrectAns(this.questionObject.answers)
      // list of correct answers not used by student
      var otherAnsList = caList.filter(ca => {
        return this.questionAnswerScoreList.reduce((accu, ma) => {
          return accu && (ma.ca !== ca)
        }, true)
      })
      return otherAnsList
    }
  },
  methods: {
    calculateScore (givenAnswerList, officialAnswerList) {
      var gaList = this.cleanGivenAnsList(givenAnswerList)
      var caList = this.listOfCorrectAns(officialAnswerList)
      var answerPairList = this.getTopPairList(gaList, caList).sort((a, b) => {
        if (a.ga > b.ga) { // correct answer with corresponding given answer on top
          return -1
        } else {
          return 1
        }
      })

      const lev2score = (levVal, wordLength) => {
        var score = 1 - levVal * 2 / wordLength
        if (score > 0) {
          return score
        } else {
          return 0
        }
      }
      var score = answerPairList.reduce((accu, ansPair) => {
        return accu + lev2score(ansPair.score, ansPair.ca.length)
      }, 0) / this.questionObject.correct_answers_needed
      score = this.roundToPrecision(score, 1)

      this.$store.commit('SET_SCORE', {
        rank: this.rank,
        score: score,
        questionIndex: this.index
      })
      return answerPairList
    },
    // take a list of given answers and a list of correct answer and return a
    // matrix with the levensthein value of all ca/ga combinations
    calcScoreMatrixWithAnswers (gaList, caList) {
      return gaList.map(
        ga => caList.map(
          ca => {
            var levVal = levenshtein(ga, ca)
            var score = 0
            if (levVal > 0) score = levVal
            return { ga, ca, score }
          }
        )
      )
    },
    listOfCorrectAns (allAnswers) {
      // TODO alternative Schreibweise auch zurückgeben.
      return allAnswers.filter(a => a.correct).map(a => {
        if (a.element !== null) { return a.element } else { return a.answer_text }
      })
    },
    getTopPairList (gaList, caList) {
      // generates list for each given answer paired with all correct answers
      // and the score of this combination
      // [[ga1, ca1, score], [ga1, ca2, score], [ga2, ca1, score], [ga2, ca2, score]]
      var levMtrxWAnswer = this.calcScoreMatrixWithAnswers(gaList, caList)
      var bestAnsList = levMtrxWAnswer.map(gaScoreList => {
        return gaScoreList.reduce((accu, pair) => {
          if (pair.score < accu.score) return pair; else return accu
        }, { score: Infinity })
      })
      return bestAnsList
    },
    cleanGivenAnsList (gaList) {
      // filter duplicates
      var cleanList = gaList.filter((ans, idx) => gaList.indexOf(ans) === idx)
      // filter null/undefined
      cleanList = cleanList.filter(ans => ans)
      // filter empty strings
      return cleanList.filter(ans => ans.length > 0)
    },
    roundToPrecision (x, precision) {
      var y = x + (precision === undefined ? 0.5 : precision / 2)
      return y - (y % (precision === undefined ? 1 : +precision))
    }
  }
}
</script>
