var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-space" },
    [
      !_vm.mediaItem
        ? _c("div", [
            _vm._v(
              "\n    Keine Medien gefunden. Bitte einen anderen Bildtyp wählen.\n  "
            )
          ])
        : _c(
            "div",
            { staticClass: "fill-space prep-card" },
            [
              _c(
                "div",
                { staticClass: "card-title-bar" },
                [
                  _c("div", { staticClass: "media-name" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.mediaItem.name) + " ")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.mediaItem.beschreibung) + " ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.$store.commit(
                            "TOGGLE_FEEDBACK_FORM",
                            _vm.feedbackPayload()
                          )
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("report")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Fehler gefunden?")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("router-view", { attrs: { name: "help" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("chart", {
                    attrs: {
                      url:
                        "" +
                        _vm.$store.getters.appState.apihost +
                        _vm.mediaItem.url,
                      "pin-list": _vm.$store.getters.appState.pinList
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("Feedback")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }