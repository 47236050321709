var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { text: "" } },
        [
          _c("v-system-bar", { attrs: { color: "secondary" } }),
          _vm._v(" "),
          _c("v-app-bar", {
            attrs: {
              dark: "",
              "clipped-left": "",
              color: "primary",
              height: "150",
              "extension-height": "37"
            },
            scopedSlots: _vm._u([
              {
                key: "extension",
                fn: function() {
                  return [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { "offset-md": "1" } }, [
                          _c(
                            "p",
                            {
                              staticClass: "text--primary",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteSWCache()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Tutorial Anatomie\n            "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { "offset-md": "1" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.unibas.ch",
                                target: "_blank"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: { "max-height": "236px" },
                                attrs: {
                                  src: "/static/img/uni-basel-logo.svg",
                                  alt: "Logo der Universität Basel"
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "600px"
          },
          model: {
            value: _vm.cacheDeletionInProgress,
            callback: function($$v) {
              _vm.cacheDeletionInProgress = $$v
            },
            expression: "cacheDeletionInProgress"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Cache wird gelöscht")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }