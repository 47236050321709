<template>
  <div>
    <v-container class="text-center pt-10">
      <v-progress-circular
        :rotate="270"
        :size="100"
        :width="15"
        :value="percentage"
        color="teal"
      >
        {{ indexCurrent }}/{{ total }}
      </v-progress-circular>
    </v-container>
    <div class="text-center">
      <v-btn text icon @click.native="skip(-total/10)">
        <v-icon large>
          fast_rewind
        </v-icon>
      </v-btn>
      <v-btn text icon @click.native="skip(-1)">
        <v-icon large>
          skip_previous
        </v-icon>
      </v-btn>
      <v-btn text icon @click.native="skip(1)">
        <v-icon large>
          skip_next
        </v-icon>
      </v-btn>
      <v-btn text icon @click.native="skip(total/10)">
        <v-icon large>
          fast_forward
        </v-icon>
      </v-btn>
    </div>
    <div class="text-center">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pa-1">
            <v-select
              v-model="bildTypFilterModel"
              clearable
              :items="evaluatedIfAvailable('bildTypFilter')"
              label="Bildtyp Filter"
              multiple
              prepend-icon="filter_list"
              solo
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                >
                  <strong>{{ data.item.name }}</strong>
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-chip
                  :disabled="data.item.disabled"
                >
                  <v-avatar left color="primary">
                    {{ preCountItems('bildTypFilter', data.item.name) }}
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="evaluatedIfAvailable('structureFilter').reduce((accu, param) => accu || !param.disabled, false)"
            cols="12"
            class="pa-1"
          >
            <v-select
              v-model="structureFilterModel"
              clearable
              :items="evaluatedIfAvailable('structureFilter')"
              label="Struktur Filter"
              multiple
              prepend-icon="filter_list"
              solo
            >
              <template v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                >
                  <strong>{{ data.item.name }}</strong>
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-chip
                  :disabled="data.item.disabled"
                >
                  <v-avatar left color="primary">
                    {{ preCountItems('structureFilter', data.item.name) }}
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    bildTypFilterModel: {
      get () {
        return this.$store.getters.activeBildTyp
      },
      set (valueList) {
        this.$store.commit('SET_BILDTYP_FILTER', valueList)
        this.reloadPins()
      }
    },
    structureFilterModel: {
      get () {
        return this.$store.getters.activeStructure
      },
      set (valueList) {
        this.$store.commit('SET_STRUCTURE_FILTER', valueList)
        this.reloadPins()
      }
    },
    indexCurrent () { return this.$route.params.index },
    currentlyFiltered () {
      return this.$store.getters.filteredMediaList(
        this.$store.getters.mediaWithPinList
      )
    },
    total () {
      return this.currentlyFiltered.length
    },
    percentage () { return this.indexCurrent / this.total * 100 }
  },
  watch: {
    // whenever filter is change, check if current index is still in range of
    // currently filtered media length
    currentlyFiltered: function (newFiltered, oldFiltered) {
      if (newFiltered.length < this.$route.params.index) {
        this.$router.push({
          name: this.$route.name,
          params: {
            index: newFiltered.length
          }
        })
      }
    }
  },
  methods: {
    reloadPins () {
      var filteredMediaList = this.$store.getters.filteredMediaList(
        this.$store.getters.mediaWithPinList
      )
      var mediaItem = filteredMediaList[this.$route.params.index - 1]
      var pinList = this.$store.getters.pinList.filter(
        (el) => el.media === Number(mediaItem.id)
      ).sort((a, b) => a.no - b.no)
      // we to control the visibility from another component via vuex store:
      this.$store.commit(
        'ADD_PINLABEL_VISIBILITY',
        pinList.map((pin) => {
          pin.isVisible = false
          return pin
        })
      )
    },
    preCountItems (filterName, filterKeyName) {
      var filter = this.$store.getters[filterName]
      var count = this.$store.state.filter.genericFilterFunc(
        this.currentlyFiltered)(filter.attributeName)(filterKeyName).length
      return count
    },
    evaluatedIfAvailable (filterName) {
      var gugus = this.$store.getters[filterName].parameter.map(p => {
        p.disabled = this.preCountItems(filterName, p.name) < 1
        return p
      })
      return gugus
    },
    skip (stepSize) {
      const index = Math.min(
        Math.max(Math.round(Number(this.indexCurrent) + stepSize), 1),
        this.total
      )
      this.$router.push({
        name: this.$route.name,
        params: {
          index: index
        }
      })
    }
  }
}
</script>
