var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-icon", { attrs: { color: "white" } }, [
            _vm._v("\n      info\n    ")
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "Bitte klicke auf einen Pin um die entsprechende Bezeichnung sichtbar zu machen."
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }