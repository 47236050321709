<template>
  <v-container class="py-0">
    <v-row>
      <v-col v-for="(o,i) in evaluateOptions()"
             :key="i"
             class="py-0 my-0"
             cols="12" sm="6"
      >
        <v-checkbox
          v-model="o.checked"
          :label="o.text"
          :error="!o.correct"
          :success="o.correct"
          readonly
          py-0 my-0
          hide-details
          light
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    rank: {
      type: String,
      default () { return 'primary' }
    },
    answerGivenList: {
      type: Array,
      default () { return [] }
    },
    presentedOptionList: {
      type: Array,
      default () { return [] }
    },
    index: {
      type: Number,
      default () { return 0 }
    }
  },
  created: function () {
    this.evaluateScore()
  },
  methods: {
    evaluateOptions () {
      var returnArr = this.presentedOptionList.map((o) => {
        var returnVal = { text: o.element || o.answer_text }
        if (this.answerGivenList.filter((a) => a === o.answer_id).length > 0) {
          returnVal.checked = true
          returnVal.correct = o.correct
        } else {
          returnVal.checked = false
          returnVal.correct = !o.correct
        }
        return returnVal
      })
      return returnArr
    },
    evaluateScore () {
      if (this.evaluateOptions().filter(o => !o.correct).length > 0) {
        this.$store.commit('SET_SCORE', {
          rank: this.rank,
          score: 0,
          challengeMode: 'simulation',
          questionIndex: this.index
        })
        return 0
      }
      this.$store.commit('SET_SCORE', {
        rank: this.rank,
        score: 1,
        challengeMode: 'simulation',
        questionIndex: this.index
      })
      return 1
    }
  }
}
</script>
