const GlobalFunction = {
  install (Vue, options) {
    Vue.prototype.$createChallenge = function (topic, challengeMode, store, router) {
      store.commit('CREATE_SIMULATION')
      store.dispatch('getQuestionList', {
        url: `/${topic}/test`,
        challengeMode
      }).then(() => {
        store.commit('CREATE_QUESTION', {
          challengeMode,
          nextIndex: 0
        })
        router.push({
          name: challengeMode,
          params: { index: '1' }
        })
      })
    }
  }
}
export default GlobalFunction
