var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-space-between" },
    [
      _c("div", { staticClass: "d-flex align-center" }, [
        _c(
          "div",
          { staticClass: "ma-1" },
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.$store.commit("SET_RIGHT_DRAWER_STATE", false)
                    }.apply(null, arguments)
                  }
                }
              },
              [_c("v-icon", [_vm._v("list")])],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", [_vm._v("Antworten")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column pa-1" }, [
        _c(
          "div",
          { staticClass: "d-flex mb-3" },
          [
            _c(
              "v-chip",
              {
                attrs: {
                  color: "primary",
                  small: "",
                  "text-color": "white",
                  witdh: "10px"
                }
              },
              [_vm._v("\n        A\n      ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "pl-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.currentQuestion.question_text) +
                  "\n      "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pl-2" }, [
          _vm.currentQuestion.typ === "Text"
            ? _c(
                "div",
                [
                  _vm.currentQuestion.correct_answers_needed > 1
                    ? _c("span", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          Wir suchen " +
                            _vm._s(_vm.currentQuestion.correct_answers_needed) +
                            " Antworten.\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.currentQuestion.correct_answers_needed, function(
                    index
                  ) {
                    return _c("ShortTextElement", {
                      key: index,
                      attrs: {
                        index: index - 1,
                        label:
                          _vm.currentQuestion.correct_answers_needed > 1
                            ? "Antwort " + index
                            : "Antwort",
                        disabled: _vm.isLocked,
                        "hide-label": true,
                        "field-name": "givenPrimaryAnswerText"
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentQuestion.typ === "Multiple choice"
            ? _c(
                "div",
                _vm._l(_vm.questionInfo["presentedPrimaryMcOptions"], function(
                  answer
                ) {
                  return _c("v-checkbox", {
                    key: answer.answer_id,
                    staticClass: "mt-0",
                    attrs: {
                      disabled: _vm.isLocked,
                      label:
                        "" +
                        (!(answer.element == "" || answer.element == null)
                          ? answer.element
                          : answer.answer_text),
                      value: answer.answer_id,
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.primaryAnswerMcModel,
                      callback: function($$v) {
                        _vm.primaryAnswerMcModel = $$v
                      },
                      expression: "primaryAnswerMcModel"
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column pa-1 mt-5" }, [
        _c(
          "div",
          { staticClass: "d-flex mb-3" },
          [
            _c(
              "v-chip",
              {
                attrs: {
                  color: "primary",
                  small: "",
                  "text-color": "white",
                  witdh: "10px"
                }
              },
              [_vm._v("\n        B\n      ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "pl-2" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.secondaryQuestion.question_text) +
                  "\n      "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pl-2" }, [
          _vm.secondaryQuestion.typ === "Text"
            ? _c(
                "div",
                [
                  _vm.secondaryQuestion.correct_answers_needed > 1
                    ? _c("span", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          Wir suchen " +
                            _vm._s(
                              _vm.secondaryQuestion.correct_answers_needed
                            ) +
                            " Antworten.\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.secondaryQuestion.correct_answers_needed, function(
                    index
                  ) {
                    return _c("ShortTextElement", {
                      key: index,
                      attrs: {
                        index: index - 1,
                        label:
                          _vm.secondaryQuestion.correct_answers_needed > 1
                            ? "Antwort " + index
                            : "Antwort",
                        disabled: _vm.isLocked,
                        "hide-label": true,
                        "field-name": "givenSecondaryAnswerText"
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.secondaryQuestion.typ === "Multiple choice"
            ? _c(
                "div",
                _vm._l(
                  _vm.questionInfo["presentedSecondaryMcOptions"],
                  function(answer) {
                    return _c("v-checkbox", {
                      key: answer.answer_id,
                      staticClass: "mt-0",
                      attrs: {
                        disabled: _vm.isLocked,
                        label:
                          "" +
                          (!(answer.element == "" || answer.element == null)
                            ? answer.element
                            : answer.answer_text),
                        value: answer.answer_id,
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.secondaryAnswerMcModel,
                        callback: function($$v) {
                          _vm.secondaryAnswerMcModel = $$v
                        },
                        expression: "secondaryAnswerMcModel"
                      }
                    })
                  }
                ),
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "white--text mt-5",
          attrs: { disabled: _vm.isLocked, color: "blue-grey" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.submit.apply(null, arguments)
            },
            mouseover: function($event) {
              _vm.showLock = "lock"
            },
            mouseleave: function($event) {
              _vm.showLock = "lock_open"
            }
          }
        },
        [
          _vm._v("\n    Antwort einloggen\n    "),
          _c("v-icon", { attrs: { right: "", dark: "" } }, [
            _vm._v("\n      " + _vm._s(_vm.showLock) + "\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }