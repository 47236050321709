<template>
<div
  class="fill-space prep-card"
  >
  <v-progress-linear
    :value="percentage"
    :color="timerColor"
    background-color="teal lighten-3"
    class="my-0"
    height="5px"
    />
  <div class="card-title-bar">
    <span
      v-if="thisQuestion"
      class="title"
      > {{ thisQuestion.question_text }} </span>
  </div>
  <router-view name="help" />
  <div class="card-body">
    <chart :url="url" :pin-list="pinList" :hide-labeling="true"/>
  </div>
</div>
</template>

<style>
.prep-card {
  display: flex;
  flex-direction: column;
  border-radius: 1%;
  background-color: #282c34;
}
.fill-space {
  height: 100%;
  width: 100%;
}
.card-title-bar {
  flex-grow: 0;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
}
.card-body {
  flex-grow: 1;
}

</style>
<script>
import Chart from './LeafletChart.vue'
export default {
  components: {
    Chart
  },
  props: {
    hideLabeling: {
      type: Boolean,
      default () {
        return false
      }
    },
    challengeMode: {
      type: String,
      default: 'simulation'
    }
  },
  computed: {
    timerColor () {
      if (this.percentage < 15) {
        return 'red'
      }
      if (this.percentage < 40) {
        return 'orange'
      }
      return 'teal'
    },
    challenge () {
      return this.$store.getters[this.challengeMode]
    },
    questionInfo () {
      return this.challenge.score.questionList[this.$route.params.index - 1] || {}
    },
    thisQuestion () {
      return this.questionInfo.questionObject || { media: [{ url: '' }] }
    },
    url () {
      return `${this.$store.getters.apihost}${this.thisQuestion.media[0].url}`
    },
    pinList () {
      return [{
        no: this.thisQuestion.media[0].pin_no,
        position: this.thisQuestion.media[0].pin_position,
        position_text: this.thisQuestion.media[0].pin_position_text
      }]
    },
    percentage () {
      return this.questionInfo.timeLeft / this.challenge.time * 100
    }
  }
}
</script>
