<template>
  <div class="d-flex flex-column justify-space-between">
    <div
      class="d-flex align-center"
    >
      <div
        class="ma-1"
      >
        <v-btn
          icon
          @click.stop="() => $store.commit('SET_RIGHT_DRAWER_STATE', false)"
        >
          <v-icon>list</v-icon>
        </v-btn>
      </div>
      <h4>Antworten</h4>
    </div>
    <!-- primary question -->
    <div class="d-flex flex-column pa-1">
      <div class="d-flex mb-3">
        <v-chip
          color="primary"
          small
          text-color="white"
          witdh="10px"
        >
          A
        </v-chip>
        <span class="pl-2">
          {{ currentQuestion.question_text }}
        </span>
      </div>
      <div class="pl-2">
        <div v-if="currentQuestion.typ === 'Text'">
          <span
            v-if="currentQuestion.correct_answers_needed > 1"
            class="caption"
          >
            Wir suchen {{ currentQuestion.correct_answers_needed }} Antworten.
          </span>
          <ShortTextElement
            v-for="index in currentQuestion.correct_answers_needed"
            :key="index"
            :index="index-1"
            :label="currentQuestion.correct_answers_needed > 1 ? `Antwort ${index}` : `Antwort`"
            :disabled="isLocked"
            :hide-label="true"
            field-name="givenPrimaryAnswerText"
          />
        </div>
        <div v-if="currentQuestion.typ === 'Multiple choice'">
          <v-checkbox
            v-for="answer in questionInfo['presentedPrimaryMcOptions']"
            :key="answer.answer_id"
            v-model="primaryAnswerMcModel"
            :disabled="isLocked"
            :label="`${!(answer.element == '' || answer.element == null ) ? answer.element : answer.answer_text }`"
            :value="answer.answer_id"
            hide-details
            class="mt-0"
          />
        </div>
      </div>
    </div>

    <!-- secondary question -->
    <div class="d-flex flex-column pa-1 mt-5">
      <div class="d-flex mb-3">
        <v-chip
          color="primary"
          small
          text-color="white"
          witdh="10px"
        >
          B
        </v-chip>
        <span class="pl-2">
          {{ secondaryQuestion.question_text }}
        </span>
      </div>
      <div class="pl-2">
        <div v-if="secondaryQuestion.typ === 'Text'">
          <span
            v-if="secondaryQuestion.correct_answers_needed > 1"
            class="caption"
          >
            Wir suchen {{ secondaryQuestion.correct_answers_needed }} Antworten.
          </span>
          <ShortTextElement
            v-for="index in secondaryQuestion.correct_answers_needed"
            :key="index"
            :index="index-1"
            :label="secondaryQuestion.correct_answers_needed > 1 ? `Antwort ${index}` : `Antwort`"
            :disabled="isLocked"
            :hide-label="true"
            field-name="givenSecondaryAnswerText"
          />
        </div>
        <div v-if="secondaryQuestion.typ === 'Multiple choice'">
          <v-checkbox
            v-for="answer in questionInfo['presentedSecondaryMcOptions']"
            :key="answer.answer_id"
            v-model="secondaryAnswerMcModel"
            :disabled="isLocked"
            :label="`${!(answer.element == '' || answer.element == null ) ? answer.element : answer.answer_text }`"
            :value="answer.answer_id"
            hide-details
            class="mt-0"
          />
        </div>
      </div>
    </div>
    <!-- submit button -->
    <v-btn
      :disabled="isLocked"
      color="blue-grey"
      class="white--text mt-5"
      @click.stop="submit"
      @mouseover="showLock = 'lock'"
      @mouseleave="showLock = 'lock_open'"
    >
      Antwort einloggen
      <v-icon right dark>
        {{ showLock }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import ShortTextElement from './AnswerTextElement.vue'
export default {
  components: {
    ShortTextElement
  },
  props: {
    challengeMode: {
      type: String,
      default: 'nix'
    }
  },
  data: function () {
    return {
      showLock: 'lock_open'
    }
  },
  computed: {
    primaryAnswerMcModel: {
      get () {
        return this.questionInfo.givenPrimaryAnswerMC
      },
      set (value) {
        const payload = {
          challengeMode: this.challengeMode,
          questionIndex: this.$route.params.index - 1,
          questionSpecification: 'givenPrimaryAnswerMC',
          data: value
        }
        this.$store.commit('SET_ANSWER', payload)
      }
    },
    secondaryAnswerMcModel: {
      get () {
        return this.questionInfo.givenSecondaryAnswerMC
      },
      set (value) {
        const payload = {
          challengeMode: this.challengeMode,
          questionIndex: this.$route.params.index - 1,
          questionSpecification: 'givenSecondaryAnswerMC',
          data: value
        }
        this.$store.commit('SET_ANSWER', payload)
      }
    },
    questionInfo () {
      return this.$store.getters[this.challengeMode].score.questionList[this.$route.params.index - 1]
    },
    isLocked () {
      return this.questionInfo.locked
    },
    currentQuestion () {
      return this.questionInfo.questionObject || { secondary_questions: [{}] }
    },
    secondaryQuestion () {
      return this.currentQuestion.secondary_questions[0] || { answers: [{}] }
    }
  },
  methods: {
    nextStep () {
      clearInterval(this.timerIntervallId)
      this.$store.commit('LOCK_QUESTION', this.index - 1)
      if (this.index < this.challObj.questionAmount) {
        this.$store.commit('CREATE_QUESTION', {
          challengeMode: this.challengeMode,
          nextIndex: this.index
        })
        this.$router.push({
          name: this.$route.name,
          params: { index: `${Number(this.index) + 1}` }
        })
        this.runTimer()
      } else {
        this.$router.push({
          name: `${this.challengeMode}-status`
        })
      }
    },
    submit () {
      this.$store.commit('TRIGGER_STEP', true)
    }
  }
}
</script>
