var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ElementLabelDisplay", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.appState.pinList[_vm.labelIndex].isVisible,
        expression: "$store.getters.appState.pinList[labelIndex].isVisible"
      }
    ],
    attrs: { element: _vm.element, labelnr: _vm.labelIndex + 1 }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }