<template>
<div class="fill-space">
  <div v-if="!mediaItem">
    Keine Medien gefunden. Bitte einen anderen Bildtyp wählen.
  </div>
  <div
    v-else
    class="fill-space prep-card"
    >
    <div class="card-title-bar">
      <div class="media-name">
        <span> {{ mediaItem.name }} </span>
        <span> {{ mediaItem.beschreibung }} </span>
      </div>
      <v-btn
        x-small
        @click.stop="$store.commit('TOGGLE_FEEDBACK_FORM', feedbackPayload())"
        >
        <v-icon>report</v-icon>
        <span>Fehler gefunden?</span>
      </v-btn>
    </div>
    <router-view name="help" />
    <div class="card-body">
      <chart :url="`${$store.getters.appState.apihost}${mediaItem.url}`" :pin-list="$store.getters.appState.pinList" />
    </div>
  </div>
  <Feedback />
</div>
</template>
<style>
.prep-card {
    display: flex;
    flex-direction: column;
    border-radius: 1%;
    background-color: #282c34;
    padding: 0 0 1rem 0;
}
.fill-space {
    height: 100%;
    width: 100%;
}
.card-title-bar {
    flex-grow: 0;
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.5rem;
    align-items: center;
}
.card-body {
  flex-grow: 1;
}

</style>

<script>
import Chart from './LeafletChart.vue'
import Feedback from './Feedback.vue'

export default {
  components: {
    Chart,
    Feedback
  },
  computed: {
    mediaItem () {
      return this.$store.getters.filteredMediaList(
        this.$store.getters.mediaWithPinList
      )[Number(this.$route.params.index) - 1]
    }
  },
  methods: {
    feedbackPayload () {
      let backendUrl = `${this.$store.getters.apihost}/adminapp/media/${this.mediaItem.id}`
      return `Betroffenes Medium: ${backendUrl}`
    }
  }
}
</script>
