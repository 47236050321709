<template>
  <v-col cols="12" md="6" lg="4">
    <v-card>
      <v-img
        :src="topic.imgUrl"
        aspect-ratio="2.75"
      />
      <v-card-title primary-title>
        <h3
          class="headline mb-0"
        >
          {{ topic.showName }}
        </h3>
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-combobox
              v-model="modusModel"
              :items="$store.getters.modusListe"
              item-text="showable"
              label="Modus"
            />
          </v-col>
          <v-col v-if="modusModel && (modusModel.route === 'anschauen' || modusModel.route === 'memorieren')"
                 class="d-flex"
                 cols="12"
          >
            <v-combobox
              v-model="bildTypModel"
              :items="$store.getters.bildTypFilter.parameter"
              :item-disabled="() => false"
              item-text="name"
              label="Typ"
            />
          </v-col>
          <v-col>
            <v-btn
              :disabled="!choice_is_valid()"
              text
              color="accent"
              @click.native="doAction"
            >
              Los geht's!
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    topic: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      modusModel: undefined,
      bildTypModel: undefined
    }
  },
  mounted: function () {
    this.$store.commit('SET_BILDTYP_FILTER', [])
  },
  methods: {
    doAction () {
      const url = `/${this.topic.name}/${this.modusModel.route}`
      this.$store.commit('SET_MODUS', this.modusModel.route)
      if (this.modusModel.route === 'anschauen' || this.modusModel.route === 'memorieren') {
        if (this.bildTypModel && (typeof this.bildTypModel.pk === 'number')) {
          this.$store.commit('SET_BILDTYP', this.bildTypNumber)
          var bildObject = this.$store.getters.bildTypFilter.parameter.filter(x => x.pk === this.bildTypModel.pk)
          this.$store.commit('SET_BILDTYP_FILTER', bildObject)
        } else {
          this.$store.commit('SET_BILDTYP_FILTER', [])
        }
      } else {
        this.$store.commit('SET_BILDTYP', 'none needed in simulation mode')
        this.$store.commit('RESET_CHALLENGE', { challengeMode: 'simulation' })
        this.$store.commit('CREATE_SIMULATION')
      }
      this.$router.push(url)
    },
    choice_is_valid () {
      return this.modusModel && (this.modusModel.route && (Object.keys(this.modusModel.route).length > 0))
    }
  }
}
</script>
