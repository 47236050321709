<template>
  <ElementLabelDisplay
    :element="element"
    :labelnr="labelIndex + 1"
  />
</template>
<script>
import ElementLabelDisplay from '../components/ElementLabelDisplay.vue'
export default {
  components: {
    ElementLabelDisplay
  },
  props: {
    element: {
      type: Object,
      default () { return {} }
    },
    labelIndex: {
      type: Number,
      default () { return 0 }
    }
  }
}
</script>
