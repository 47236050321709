var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "my-0 py-5 px-5" },
    [
      _vm.questionAnswerScoreList.length === 0
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "my-0 py-0 px-3",
                  attrs: { cols: "12", sm: "6", md: "3" }
                },
                [_vm._v("\n      Keine Antwort\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.questionAnswerScoreList, function(textResult, i) {
        return _c(
          "div",
          { key: i },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "px-3 my-0 py-0",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                value: textResult.ga,
                                success: textResult.levVal < 10,
                                error: textResult.levVal >= 10,
                                readonly: "",
                                hint: "Ihre Antwort",
                                "persistent-hint": ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "my-0 py-0",
                            attrs: { cols: "12", sm: "6" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                value: textResult.ca,
                                success: "",
                                readonly: "",
                                hint: "Richtige Antwort",
                                "persistent-hint": ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.getOtherCorrectAnswerList.length > 0
        ? _c(
            "v-row",
            { staticClass: "x12 pt-3" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-1", attrs: { x12: "" } },
                    [
                      _c("v-col", { staticClass: "py-0" }, [
                        _vm._v("\n          Korrekte\n          "),
                        _c("span", [_vm._v("Antwort")]),
                        _vm.getOtherCorrectAnswerList.length > 1
                          ? _c("span", [_vm._v("en")])
                          : _vm._e(),
                        _c("span", [_vm._v(":")])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "my-1" },
                    _vm._l(_vm.getOtherCorrectAnswerList, function(ans, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          staticClass: "px-3 py-0",
                          attrs: { cols: "12", sm: "6", md: "4" }
                        },
                        [_vm._v("\n          " + _vm._s(ans) + "\n        ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }