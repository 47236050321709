var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            scrollable: "",
            "max-width": "600px"
          },
          model: {
            value: _vm.$store.getters.feedBackFormIsDisplayed,
            callback: function($$v) {
              _vm.$set(_vm.$store.getters, "feedBackFormIsDisplayed", $$v)
            },
            expression: "$store.getters.feedBackFormIsDisplayed"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Einen Fehler melden")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          model: {
                                            value: _vm.feedbackType,
                                            callback: function($$v) {
                                              _vm.feedbackType = $$v
                                            },
                                            expression: "feedbackType"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Ein inhaltlicher Fehler",
                                              value: "content"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Ein technischer Fehler",
                                              value: "technical"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.feedbackType === "technical"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                    info\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                  Unterstützt sind die Browser Firefox und Chrome. Bitte\n                  beachten Sie, dass Probleme mit anderen Browsern nicht\n                  berücksichtigt werden können.\n                "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Beschreibung des Fehlers*",
                                      hint:
                                        "Bitte den Fehler möglichst präzise umschreiben",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: _vm.emailRules,
                                      label: "Email Adresse*",
                                      hint:
                                        "Bitte hinterlassen Sie Ihre Emailadresse, damit wir uns bei Unklarheiten rückmelden können."
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.$store.commit("TOGGLE_FEEDBACK_FORM")
                            }
                          }
                        },
                        [_vm._v("\n            Abbrechen\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.valid,
                            color: "blue darken-1",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.sendFeedback()
                              _vm.$store.commit("TOGGLE_FEEDBACK_FORM")
                            }
                          }
                        },
                        [_vm._v("\n            Senden\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }