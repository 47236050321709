<template>
  <v-row>
    <v-dialog
      v-model="$store.getters.feedBackFormIsDisplayed"
      transition="dialog-bottom-transition"
      scrollable
      max-width="600px"
    >
      <v-card tile>
        <v-form v-model="valid">
          <v-card-title>
            <span class="headline">Einen Fehler melden</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="feedbackType"
                    >
                      <v-radio
                        label="Ein inhaltlicher Fehler"
                        value="content"
                      />
                      <v-radio
                        label="Ein technischer Fehler"
                        value="technical"
                      />
                    </v-radio-group>
                  </v-col>
                  <div v-if="feedbackType === 'technical'">
                    <v-icon small>
                      info
                    </v-icon>
                    Unterstützt sind die Browser Firefox und Chrome. Bitte
                    beachten Sie, dass Probleme mit anderen Browsern nicht
                    berücksichtigt werden können.
                  </div>
                  <v-text-field
                    v-model="description"
                    label="Beschreibung des Fehlers*"
                    hint="Bitte den Fehler möglichst präzise umschreiben"
                    required
                  />
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email Adresse*"
                    hint="Bitte hinterlassen Sie Ihre Emailadresse, damit wir uns bei Unklarheiten rückmelden können."
                  />
                </v-col>
              </v-row>
            </v-container>
            <!--<small>*indicates required field</small>
              -->
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
                color="blue darken-1" text @click="$store.commit('TOGGLE_FEEDBACK_FORM')">
              Abbrechen
            </v-btn>
            <v-btn
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="sendFeedback(); $store.commit('TOGGLE_FEEDBACK_FORM');">
              Senden
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import store from '../store'
export default {
  data: () => ({
    description: '',
    feedbackType: 'content',
    email: '',
    emailRules: [
      (v) => {
        if (!v) return false
        const isEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w*)+$/.test(v)
        const noInput = v.length === 0
        if (isEmail || noInput) {
          return true
        } else {
          return 'Keine gültige Emailaddresse'
        }
      }
    ],
    valid: false
  }),
  methods: {
    sendFeedback () {
      let desc = this.description
      let msg = `${this.description}\n${store.getters.feedbackMessage}`
      store.dispatch('sendFeedback', {
        description: msg,
        feedbackType: this.feedbackType,
        sender: this.email,
        appState: JSON.stringify(store.getters.appState),
        browserInfo: JSON.stringify(store.getters.browserInfo)
      })
    }
  }
}
</script>
