var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h1", [_vm._v(" Prüfungssimulation ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Anzahl Fragen: " +
              _vm._s(_vm.$store.getters.simulation.questionAmount) +
              " "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Zeit pro Frage in Sekunden: " +
              _vm._s(_vm.$store.getters.simulation.time) +
              " "
          )
        ]),
        _vm._v(" "),
        Object.keys(_vm.$store.getters.simulation).length === 0 ||
        _vm.$store.getters.simulation.score.questionList.length === 0
          ? _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary darken-2", "text-color": "white" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$createChallenge(
                          _vm.$route.params.topic,
                          _vm.challengeMode,
                          _vm.$store,
                          _vm.$router
                        )
                      }
                    }
                  },
                  [_vm._v("\n        Challenge starten\n      ")]
                )
              ],
              1
            )
          : _vm.$store.getters.simulation.score.questionList.length <
            _vm.$store.getters.simulation.questionAmount
          ? _c("div", [
              _c("p", [
                _vm._v(
                  "anzahl beantworteter fragen: " +
                    _vm._s(
                      _vm.$store.getters.simulation.score.questionList.length
                    ) +
                    " "
                )
              ])
            ])
          : (_vm.$store.getters["simulation"].score.questionList.length =
              _vm.$store.getters.simulation.questionAmount)
          ? _c(
              "div",
              [
                _c("h3", [_vm._v(" " + _vm._s(_vm.resultMessage) + " ")]),
                _vm._v(" "),
                _c(
                  "v-expansion-panels",
                  { attrs: { popout: "" } },
                  _vm._l(
                    _vm.$store.getters.simulation.score.questionList,
                    function(q, i) {
                      return _c("Result", { key: i, attrs: { i: i, q: q } })
                    }
                  ),
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }