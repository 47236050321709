import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import GlobalFunction from './globalFunction.js'

Vue.use(Vuetify)

const opts = {
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#a5d7d2',
        accent: '#D20537',
        secondary: '#2d373c',
        info: '#D2EBE8',
        warning: '#ffa000',
        error: '#ff5252',
        success: '#81c784',
        indetermined: '#c8c8c8',
        white: '#ffffff',
        black: '#000000',
        unibasGrey: '#282C34'
      }
    }
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  }
}

new Vue({ // eslint-disable-line no-new
  el: '#app',
  router,
  store,
  vuetify: new Vuetify(opts),
  data: { version: '1.0.3' },
  created: async function () {
    if ('serviceWorker' in navigator) {
      const version = this.$store.getters.version
      if (version !== this.version) {
        this.$store.commit('RESET')
        this.$store.commit('SET_VERSION', this.version)
      }
      try {
        const reg = await navigator.serviceWorker.register(`/sw.js?version=${version}`)
        if (reg.installing) console.log('SW installing')
        else if (reg.waiting) console.log('SW waiting')
        else if (reg.active) console.log('SW active')
      } catch (regError) {
        console.log('SW reg failed: ', regError)
      }
    }
  },
  render: h => h(App)
})
Vue.config.errorHandler = function (err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`)
  store.dispatch('logErrorOnBackend', { error: err.toString(), info })
}
Vue.use(GlobalFunction)
