var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "10" } },
        [
          _c("v-text-field", {
            attrs: { outlined: "", label: "Suche", type: "text" },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c("v-chip", { attrs: { right: "", color: "primary" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.filteredList.length) +
                          "\n        "
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.searchTokenModel,
              callback: function($$v) {
                _vm.searchTokenModel = $$v
              },
              expression: "searchTokenModel"
            }
          }),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { rounded: "" } },
            _vm._l(_vm.visibleItems, function(item, index) {
              return _c(
                "v-lazy",
                {
                  key: item.id,
                  model: {
                    value: _vm.isActive,
                    callback: function($$v) {
                      _vm.isActive = $$v
                    },
                    expression: "isActive"
                  }
                },
                [
                  _c(
                    "v-list-item",
                    { class: index % 2 == 0 ? "white" : "teal lighten-5" },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-avatar", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.id) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(item.name) + " ")
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(item.name_alt) + " ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row flex-wrap" },
                            _vm._l(item.media_list, function(pic) {
                              return _c(
                                "div",
                                {
                                  key: pic.id,
                                  staticClass: "d-flex flex-row pa-2"
                                },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        avatar: "",
                                        left: "",
                                        overlap: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "badge",
                                            fn: function() {
                                              return [
                                                _c("v-avatar", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(pic.id) +
                                                      "\n                    "
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-avatar", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.$store.getters.appState
                                                .apihost +
                                              "/media/" +
                                              pic.url
                                          }
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column ml-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [_vm._v(" " + _vm._s(pic.name) + " ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(pic.beschreibung) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-pagination", {
            attrs: { length: Math.ceil(_vm.filteredList.length / _vm.perPage) },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }