import Vue from 'vue'

import Vuex from 'vuex'

import router from '../router'
import uiState from './modules/uiState.js'
import filter from './modules/filter.js'
import createPersistedState from 'vuex-persistedstate'

const apihost = process.env.API_HOST ? process.env.API_HOST : 'http://localhost:8000'
console.log('API host is: ', apihost)
const defaultLength = 20
const defaultTime = 60

Vue.use(Vuex)

const initialState = {
  appData: {
    isLoading: false,
    updateCounter: 0,
    pinList: [],
    mediaList: [],
    fullMediaList: [],
    mediaWithPinList: []
  },
  appState: {
    pinList: [], // true/false for each label in curr..ElementList
    version: '',
    simulation: {},
    test: {},
    apihost,
    session: {
      bildTypList: []
    }
  },
  browserInfo: getBrowserInfo()
}

const NUMBER_OF_ANSWERS = 4

function getBrowserInfo () {
  return {
    platform: navigator.platform,
    userAgent: navigator.userAgent
  }
}

function getShuffledAnswerArray (q) {
  const answers = []
  const shuffledA = shuffleArray(q.answers)
  const correctA = shuffledA.filter(a => a.correct)
  const falseA = shuffledA.filter(a => !a.correct)
  // Fill in as many correct answers as needed
  answers.push(
    ...correctA.filter((a, idx, arr) => {
      if (idx < q.correct_answers_needed) {
        // this item will be used, so we have to remove it from the pool of
        // available correct answers
        arr.splice(idx, 1)
        return true
      }
      return false
    })
  )
  // Fill up the remaining free places with wrong answers
  answers.push(
    ...falseA.filter((a, idx) => answers.length + idx < NUMBER_OF_ANSWERS)
  )
  // if not enough wrong answers available, we have to add more correct answers
  answers.push(
    ...correctA.filter((a, idx) => answers.length + idx < NUMBER_OF_ANSWERS)
  )
  return shuffleArray(answers)
}

function shuffleArray (a) {
  // make a copy of an array and shuffle and return it
  const b = a || []
  const tmp = b.slice(0)
  for (let i = tmp.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = tmp[i]
    tmp[i] = tmp[j]
    tmp[j] = temp
  }
  return tmp
}

const modusListe = [{
  name: 'view',
  route: 'anschauen',
  showable: 'Anschauen'
}, {
  name: 'memorize',
  route: 'memorieren',
  showable: 'Memorieren'
}, {
  name: 'simulation',
  route: 'simulation',
  showable: 'Prüfungssimulation'
}]

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'appState'
      ]
    })
  ],

  state: Vue.util.extend({}, initialState),

  actions: {
    getQuestionList ({ commit, state, dispatch }, payload) {
      commit('START_UPDATE')
      const url = `${apihost}${payload.url}/`
      return fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(stream => stream.json())
        .then(response => {
          commit('SET_QUESTION_LIST', {
            challengeMode: payload.challengeMode,
            result: response,
            total: response.length
          })
        }).catch(error => {
          dispatch('logErrorOnBackend', error)
        }).finally(() => {
          commit('UPDATE_DONE')
        })
    },

    sendFeedback ({ commit, state, dispatch }, payload) {
      const url = `${apihost}/feedback/`
      return fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(payload) // body data type must match "Content-Type" header
      }).then(
        response => console.log(response)
      ) // parses JSON response into native Javascript objects
    },
    logErrorOnBackend ({ commit, state }, error) {
      const url = `${apihost}/log_error/`
      return fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({
          errorMsg: JSON.stringify(error), // body data type must match "Content-Type" header
          appState: JSON.stringify(store.getters.appState)
        })
      }).then(
        response => console.log(response)
      )
    },
    getList ({ commit, state, dispatch }, payload) {
      commit('START_UPDATE')
      const url = `${apihost}${payload.url}/?limit=999999`
      return fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(stream => stream.json())
        .then(response => {
          commit('SET_LIST', {
            name: payload.name,
            result: response.results,
            total: response.length
          })
        }).catch(error => {
          dispatch('logErrorOnBackend', error)
        }).finally(() => {
          commit('UPDATE_DONE')
        })
    }
  },
  getters: {
    state: state => state,
    apihost: state => apihost,
    pinList: state => state.appData.pinList,
    // not all media items got pins assigned, lets filter those with pins:
    elementList: state => state.appData.elementList,
    fullMediaList: state => state.appData.fullMediaList,
    mediaWithPinList: state => state.appData.mediaWithPinList,
    appState: state => state.appState,
    appData: state => state.appData,
    browserInfo: state => state.browserInfo,
    simulation: state => state.appState.simulation,
    modusListe: state => modusListe,
    version: state => state.appState.version
  },

  mutations: {
    'SET_VERSION' (state, payload) {
      state.appState.version = payload
    },
    'START_UPDATE' (state) {
      state.appData.updateCounter++
      state.appData.isLoading = true
    },
    'UPDATE_DONE' (state, payload) {
      if (state.appData.updateCounter > 0) state.appData.updateCounter--
      state.appData.isLoading = !(state.appData.updateCounter === 0)
    },
    'SET_FULL_LIST' (state, payload) {
      let mediaList = payload.result
      mediaList = mediaList.map(m => {
        m.topic = payload.topic
        if (process.env.NODE_ENV === 'production') {
          m.url = m.url.replace('http', 'https')
        }
        return m
      })
      state.appData[payload.name].push(...mediaList)
    },
    'SET_LIST' (state, payload) {
      Vue.set(state.appData, payload.name, payload.result)
    },
    'SET_QUESTION_LIST' (state, payload) {
      Vue.set(state.appState[payload.challengeMode], 'rawQuestionList', payload.result)
    },
    'CREATE_SIMULATION' (state, time = defaultTime, amount = defaultLength) {
      Vue.set(state.appState, 'simulation', {
        time,
        questionAmount: amount,
        rawQuestionList: [],
        score: {
          questionList: []
        }
      })
    },
    'CREATE_TEST' (state, time = defaultTime, length = defaultLength) {
      state.appState.test = {
        time,
        length,
        score: {
          questionList: []
        }
      }
    },
    'RESET_CHALLENGE' (state, params) {
      if (
        state.appState[params.challengeMode] &&
        state.appState[params.challengeMode].score &&
        state.appState[params.challengeMode].score.questionList
      ) {
        const qList = state.appState[params.challengeMode].score.questionList
        for (let i = 0; i < qList.length; i++) {
          Vue.delete(qList, i)
        }
        Vue.delete(state.appState[params.challengeMode].score, 'questionList')
        Vue.delete(state.appState, params.challengeMode)
      }
    },
    'COUNTDOWN_TIMER' (state, params) {
      if (state.appState[params.challengeMode].score.questionList[params.questionIndex].timeLeft > 0) {
        state.appState[params.challengeMode].score.questionList[params.questionIndex].timeLeft--
      }
    },
    'CREATE_QUESTION' (state, params) {
      const rawQuestion = state.appState[params.challengeMode].rawQuestionList[params.nextIndex]
      // debugging error if sec question list is empty
      let secQuestion = {}
      if (rawQuestion.secondary_questions.length > 0) {
        secQuestion = rawQuestion.secondary_questions[0]
      }
      const qObj = {
        questionObject: rawQuestion,
        timeLeft: state.appState.simulation.time,
        givenPrimaryAnswerMC: [],
        givenPrimaryAnswerText: [],
        givenSecondaryAnswerMC: [],
        givenSecondaryAnswerText: [],
        locked: false,
        presentedPrimaryMcOptions: getShuffledAnswerArray(rawQuestion),
        presentedSecondaryMcOptions: getShuffledAnswerArray(secQuestion),
        score: {}
      }
      Vue.set(state.appState[params.challengeMode].score.questionList, params.nextIndex, qObj)
      if (rawQuestion.typ === 'Text') {
        for (let index = 0; index < rawQuestion.correct_answers_needed; index++) {
          Vue.set(state.appState[params.challengeMode].score.questionList[params.nextIndex].givenPrimaryAnswerText, index, '')
        }
      }
      if (rawQuestion.secondary_questions[0].typ === 'Text') {
        for (let index = 0; index < rawQuestion.secondary_questions[0].correct_answers_needed; index++) {
          Vue.set(state.appState[params.challengeMode].score.questionList[params.nextIndex].givenSecondaryAnswerText, index, '')
        }
      }
    },
    'LOCK_QUESTION' (state, index) {
      index = router.currentRoute.params.index - 1
      Vue.set(state.appState.simulation.score.questionList[index], 'locked', true)
    },
    'SET_MEDIA_WITH_PINS' (state) {
      let mediaWithPins = state.appData.mediaList.filter(
        mediaItem => state.appData.pinList.filter(
          pinItem => pinItem.media === mediaItem.id
        ).length > 3
      )
      // in production we need https or else there is a mixed content error in firefox
      if (process.env.NODE_ENV === 'production') {
        mediaWithPins = mediaWithPins.map(m => {
          m.url = m.url.replace('http', 'https')
          return m
        })
      }
      Vue.set(state.appData, 'mediaWithPinList', mediaWithPins)
    },
    'SET_TOPIC' (state, topic) {
      state.appState.session.topic = topic
    },
    'SET_MODUS' (state, modus) {
      state.appState.session.modus = modus
    },
    'SET_SELECTED_BILDTYP_LIST' (state, bildtypliste) {
    },
    'SET_BILDTYP' (state, bildtyp) {
      state.appState.session.bildTyp = bildtyp
    },
    'SET_SCORE' (state, p) {
      Vue.set(
        state.appState[state.appState.session.modus].score.questionList[p.questionIndex].score,
        p.rank,
        p.score
      )
    },
    'SET_ANSWER' (state, p) {
      Vue.set(state.appState[p.challengeMode].score.questionList[p.questionIndex], p.questionSpecification, p.data)
    },
    'SET_TEXTANSWER' (state, p) {
      const questionIndx = router.currentRoute.params.index
      const currentQuestion = state.appState[state.appState.session.modus].score.questionList[questionIndx - 1]
      Vue.set(currentQuestion[p.fieldName], p.index, p.data)
    },
    'ADD_PINLABEL_VISIBILITY' (state, pinList) {
      state.appState.pinList = pinList
    },
    'TOGGLE_ELEMENT_LABEL_VISIBILITY' (state, index) {
      // does not work because of Vue store array update problem
      // state.appState.pinList[index].isVisible = !state.appState.pinList[index].isVisible
      state.appState.pinList = state.appState.pinList.map((pin, idx) => {
        if (idx === index) pin.isVisible = !pin.isVisible
        return pin
      })
    },
    'RESET' (state) {
      for (const key in initialState) {
        Vue.set(state, key, initialState[key])
      }
    }
  },
  modules: {
    uiState,
    filter
  }
})

export default store
