var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", lg: "4" } },
    [
      _c(
        "v-card",
        [
          _c("v-img", {
            attrs: { src: _vm.topic.imgUrl, "aspect-ratio": "2.75" }
          }),
          _vm._v(" "),
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("h3", { staticClass: "headline mb-0" }, [
              _vm._v("\n        " + _vm._s(_vm.topic.showName) + "\n      ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          items: _vm.$store.getters.modusListe,
                          "item-text": "showable",
                          label: "Modus"
                        },
                        model: {
                          value: _vm.modusModel,
                          callback: function($$v) {
                            _vm.modusModel = $$v
                          },
                          expression: "modusModel"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.modusModel &&
                  (_vm.modusModel.route === "anschauen" ||
                    _vm.modusModel.route === "memorieren")
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.$store.getters.bildTypFilter.parameter,
                              "item-disabled": function() {
                                return false
                              },
                              "item-text": "name",
                              label: "Typ"
                            },
                            model: {
                              value: _vm.bildTypModel,
                              callback: function($$v) {
                                _vm.bildTypModel = $$v
                              },
                              expression: "bildTypModel"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.choice_is_valid(),
                            text: "",
                            color: "accent"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.doAction.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n            Los geht's!\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }