import Vue from 'vue'
import drawer from './sideDrawerState.js'
import notification from './notification.js'

const initialState = {
  showFeedBackForm: false,
  feedbackMessage: '',
  nextStep: false
}

const state = Vue.util.extend({}, initialState)

const getters = {
  feedBackFormIsDisplayed: state => state.showFeedBackForm,
  nextStep: state => state.nextStep,
  feedbackMessage: state => state.feedbackMessage
}

const modules = {
  drawer,
  notification
}

const mutations = {
  'TRIGGER_STEP' (state, params) {
    state.nextStep = params
  },
  'TOGGLE_FEEDBACK_FORM' (state, msg) {
    // On the result page of the test simulation, there are several answers and
    // each of them opens a feedback form dialog. Vuejs cannot pass props to
    // these dialogs, so we have to pass the message as an argument and save it
    // in the state
    if (state.showFeedBackForm) {
      state.feedbackMessage = ''
    } else {
      state.feedbackMessage = msg
    }
    state.showFeedBackForm = !state.showFeedBackForm
  },
  'RESET' (state) {
    for (const key in initialState) {
      Vue.set(state, key, initialState[key])
    }
  }
}

export default {
  state,
  getters,
  modules,
  mutations
}
