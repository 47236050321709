var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      attrs: {
        "input-value": _vm.$vuetify.breakpoint.mdAndDown,
        app: "",
        fixed: ""
      }
    },
    [
      _c(
        "v-btn",
        {
          nativeOn: {
            click: function($event) {
              return _vm.skip(-_vm.total / 10)
            }
          }
        },
        [_c("v-icon", [_vm._v("\n      fast_rewind\n    ")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          nativeOn: {
            click: function($event) {
              return _vm.skip(-1)
            }
          }
        },
        [_c("v-icon", [_vm._v("\n      skip_previous\n    ")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-chip",
        {
          staticStyle: { flex: "0 1 auto" },
          attrs: { color: "primary darken-2", "text-color": "white" }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.indexCurrent) +
              "/" +
              _vm._s(_vm.total) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          nativeOn: {
            click: function($event) {
              return _vm.skip(1)
            }
          }
        },
        [_c("v-icon", [_vm._v("\n      skip_next\n    ")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          nativeOn: {
            click: function($event) {
              return _vm.skip(_vm.total / 10)
            }
          }
        },
        [_c("v-icon", [_vm._v("\n      fast_forward\n    ")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }