var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "pa-1" },
    [
      _c(
        "v-list-item",
        { attrs: { "two-line": "", tag: "div" } },
        [
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return function() {
                            return _vm.$store.commit(
                              "SET_RIGHT_DRAWER_STATE",
                              false
                            )
                          }.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("chevron_right")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-list-item-avatar", [_c("v-icon", [_vm._v("list")])], 1),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [_vm._v("\n        Legende\n      ")]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm._v("\n        Bitte auf die Pins klicken\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.$store.getters.appState.pinList, function(element, index) {
        return _c("router-view", {
          key: element.position,
          attrs: {
            "label-index": index,
            element: element,
            name: "elementLabel"
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }