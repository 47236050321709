<template>
  <div>
    <div class="text-center">
      <h5
        v-if="challengeIsComplete"
      >
        Du hast bereits alle Fragen beantwortet.
        <a :href="'#/' + $route.params.topic + '/simulation'">
          Zurück zur Resultatseite gehen.
        </a>
      </h5>
      <h3>Frage {{ index }}</h3>
    </div>
    <v-container class="text-center pt-10">
      <v-progress-circular
        :rotate="270"
        :size="100"
        :width="15"
        :value="percentage"
        :color="timerColor"
      >
        {{ challObj.score.questionList[index - 1].timeLeft }}
      </v-progress-circular>
    </v-container>
    <div class="text-center">
      <v-btn
        color="primary darken-2"
        text-color="white"
        @click.native="cancelChallenge"
      >
        Abbrechen
      </v-btn>
      <v-btn
        color="primary darken-2"
        text-color="white"
        @click.native="nextStep()"
      >
        Weiter
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    challengeMode: {
      type: String,
      default: 'nix'
    }
  },
  data () {
    return {
      timerIntervallId: null
    }
  },
  computed: {
    challengeIsComplete () {
      return this.challObj.score.questionList.reduce(
        (acc, i) => acc && i.locked, // check if all questions are locked
        // init value is true if the amount of q in our array equals
        // the total amount of q
        this.challObj.score.questionList.length === this.challObj.questionAmount
      )
    },
    challObj () {
      return this.$store.getters[this.challengeMode]
    },
    timerColor () {
      if (this.percentage < 15) {
        return 'red'
      }
      if (this.percentage < 40) {
        return 'orange'
      }
      return 'teal'
    },
    index () {
      return this.$route.params.index
    },
    percentage () {
      return this.challObj.score.questionList[this.index - 1].timeLeft / this.challObj.time * 100
    }
  },
  watch: {
    '$store.state.uiState.nextStep': function () {
      if (this.$store.getters.nextStep === true) {
        this.$store.commit('TRIGGER_STEP', false)
        this.nextStep()
      }
    }
  },
  created () {
    this.runTimer()
  },
  beforeDestroy () {
    clearInterval(this.timerIntervallId)
  },
  methods: {
    nextStep () {
      clearInterval(this.timerIntervallId)
      this.$store.commit('LOCK_QUESTION', this.index - 1)
      if (this.index < this.challObj.questionAmount) {
        this.$store.commit('CREATE_QUESTION', {
          challengeMode: this.challengeMode,
          nextIndex: this.index
        })
        this.$router.push({
          name: this.$route.name,
          params: { index: `${Number(this.index) + 1}` }
        })
        this.runTimer()
      } else {
        this.$router.push({
          name: `${this.challengeMode}-status`
        })
      }
    },
    cancelChallenge () {
      this.$store.commit('RESET_CHALLENGE', { challengeMode: this.challengeMode })
      this.$store.commit('CREATE_SIMULATION')
      this.$router.push({ name: 'simulation-status' })
    },
    runTimer () {
      if (!this.challObj.score.questionList[this.index - 1].locked) {
        this.timerIntervallId = setInterval(() => {
          this.$store.commit('COUNTDOWN_TIMER', {
            challengeMode: this.challengeMode,
            questionIndex: this.index - 1
          })
          if (this.challObj.score.questionList[this.index - 1].timeLeft === 0) {
            this.nextStep()
          }
        }, 1000)
      }
    }
  }
}
</script>
