var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      Object.keys(_vm.$store.getters[_vm.challengeMode]).length === 0 ||
      _vm.$store.getters[_vm.challengeMode].score.questionList.length === 0
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-2", "text-color": "white" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$createChallenge(
                        _vm.$route.params.topic,
                        _vm.challengeMode,
                        _vm.$store,
                        _vm.$router
                      )
                    }
                  }
                },
                [_vm._v("\n        Challenge starten\n      ")]
              )
            ],
            1
          )
        : _vm.$store.getters[_vm.challengeMode].score.questionList.length <
          _vm.$store.getters[_vm.challengeMode].questionAmount
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-2", "text-color": "white" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.continueChallenge()
                    }
                  }
                },
                [_vm._v("\n        Challenge fortsetzen\n      ")]
              )
            ],
            1
          )
        : _vm.$store.getters[_vm.challengeMode].score.questionList.length <=
          _vm.$store.getters[_vm.challengeMode].questionAmount
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-2", "text-color": "white" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$createChallenge(
                        _vm.$route.params.topic,
                        _vm.challengeMode,
                        _vm.$store,
                        _vm.$router
                      )
                    }
                  }
                },
                [_vm._v("\n        Neue Challenge starten\n      ")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }