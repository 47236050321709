<template>
<v-expansion-panels popout>
  <Result
    :i="0"
    :q="decodeAnswer"
  />
</v-expansion-panels>
</template>
<script>
import Result from './Result.vue'
export default {
  components: {
    Result
  },
  props: {
    answer: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
    decodeAnswer () {
      let deco = JSON.parse(decodeURIComponent(this.answer))
      return deco
    }
  }
}
</script>
