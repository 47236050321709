var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        temporary:
          _vm.$vuetify.breakpoint[_vm.$store.getters.rightDrawerTemporarySize],
        permanent: _vm.showPermanently,
        clipped: "",
        right: "",
        app: ""
      },
      model: {
        value: _vm.showRightDrawer,
        callback: function($$v) {
          _vm.showRightDrawer = $$v
        },
        expression: "showRightDrawer"
      }
    },
    [_c("router-view", { attrs: { name: "rightDrawerContent" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }