<template>
  <v-text-field
    ref="field"
    v-model="inputModel"
    :disabled="disabled"
    :label="label"
    :rules="inputRules"
    filled
  />
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    fieldName: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    inputRules: {
      type: Array,
      default () { return [] }
    }
  },
  computed: {
    inputModel: {
      get () {
        var questionIndx = this.$route.params.index
        var currentQuestion = this.$store.getters.appState[this.$store.getters.appState.session.modus].score.questionList[questionIndx - 1]
        return currentQuestion[this.fieldName][this.index] || ''
      },
      set (value) {
        const isValid = this.$refs.field.validate()
        if (isValid) {
          const payload = {
            fieldName: this.fieldName,
            index: this.index,
            data: value
          }
          this.$store.commit('SET_TEXTANSWER', payload)
        }
      }
    },
    isIndetermined () {
      return !this.inputModel || this.inputModel.length === 0
    },
    getOpacity () {
      if (this.isIndetermined) return 0.3
      else return 1
    }
  }
}
</script>
