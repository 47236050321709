var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { ref: _vm.element.element.name },
    [
      _c(
        "v-chip",
        { attrs: { color: "primary darken-2", "text-color": "white" } },
        [_vm._v("\n    " + _vm._s(_vm.labelnr) + "\n  ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [
        _vm._v("\n    " + _vm._s(_vm.element.element.name_med) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }