var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "field",
    attrs: {
      disabled: _vm.disabled,
      label: _vm.label,
      rules: _vm.inputRules,
      filled: ""
    },
    model: {
      value: _vm.inputModel,
      callback: function($$v) {
        _vm.inputModel = $$v
      },
      expression: "inputModel"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }