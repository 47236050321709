import Vue from 'vue'

const initialState = {
  showCockpit: true,
  showRightDrawer: true
}

const state = Vue.util.extend({}, initialState)

const getters = {
  showCockpit: state => state.showCockpit,
  showRightDrawer: state => state.showRightDrawer,
  cockpitTemporarySize: () => 'mdAndDown',
  rightDrawerTemporarySize: () => 'xsOnly',
  rightDrawerPermanentSize: () => 'smAndUp'
}

const mutations = {
  'SET_COCKPIT_STATE' (state, value) {
    state.showCockpit = value
    // only one drawer can be open at a time
    // if (value === true) state.showRightDrawer = false
  },
  'SET_RIGHT_DRAWER_STATE' (state, value) {
    state.showRightDrawer = value
    // only one drawer can be open at a time
    // if (value === true) state.showCockpit = false
  },
  'RESET' (state) {
    for (const key in initialState) {
      Vue.set(state, key, initialState[key])
    }
  }
}

export default {
  state,
  getters,
  mutations
}
