import Vue from 'vue'

const unifyArray = arrA => arrB => [...new Set([...arrA, ...arrB])]
const interArray = arrA => arrB => arrA.filter(x => arrB.includes(x))
const filterSingleIdFunc = objList => attrName => filterkey => objList.filter(obj => {
  return obj[attrName].includes(filterkey) // obj[attrName] is an array (struct_list)
})

const filterMultipleIdFunc = mediaList => attrName => activeFilterList => combineOperation => activeFilterList.reduce(
  (accu, activeFilter) => combineOperation(accu)(filterSingleIdFunc(accu)(attrName)(activeFilter.name)), mediaList
)

const initialState = {
  unifyArray,
  interArray,
  genericFilterFunc: filterSingleIdFunc,
  filterMultipleIdFunc,
  mediaFilterList: [{ // we predefine bildTypen here
    internalName: 'bildTyp',
    attributeName: 'typ',
    displayName: 'Bild Typ',
    parameter: [{
      name: 'Foto'
    }, {
      name: 'Schnitt'
    }, {
      name: 'Radiologie'
    }]
  }, { // but there are too many structures, so we fetch them from the backend
    internalName: 'structure',
    attributeName: 'structure_list',
    displayName: 'Struktur',
    parameter: [] // different ids for each topic because of db messup, get then on initialisation
  }],
  elementFilterList: [{
    internalName: 'elementName',
    attributeName: 'name',
    displayName: 'Name des Elements',
    parameter: ''
  }, {
    internalName: 'elementAltName',
    attributeName: 'name_alt',
    displayName: 'Alternativer Name des Elements',
    parameter: ''
  }],
  filterState: {
    structure: {
      itemList: [],
      operation: interArray
    },
    bildTyp: {
      itemList: [],
      operation: interArray
    },
    elementName: {
      searchToken: ''
    },
    elementAltName: {
      searchToken: ''
    }
  },
  fiteredMediaList: [],
  filteredElementList: []
}

const state = Vue.util.extend({}, initialState)

const getters = {
  bildTypFilter: state => state.mediaFilterList[0],
  structureFilter: state => state.mediaFilterList[1],
  elementNameFilter: state => state.elementFilterList[0],
  activeBildTyp: state => state.filterState.bildTyp.itemList,
  activeStructure: state => state.filterState.structure.itemList,
  activeElementSearchToken: state => state.filterState.elementName.searchToken,
  filteredMediaList: state => mediaList => state.mediaFilterList.reduce(
    (accu, filter) => {
      const fState = state.filterState[filter.internalName]
      const filtered = state.filterMultipleIdFunc(accu)(filter.attributeName)(fState.itemList)(fState.operation)
      return filtered
    }, mediaList
  ),
  filteredElementList: state => elementList => elementList.filter(el => {
    return state.elementFilterList.reduce((accu, filter) => {
      const fState = state.filterState[filter.internalName]
      const find = el[filter.attributeName].includes(fState.searchToken) // obj[attrName] is an array (struct_list)
      return find || accu
    }, false)
  })
}

const mutations = {
  'SET_STRUCTURE_LIST' (state, structureList) {
    // das Backend schickt ein Array mit eine völlig sinnlosen ersten Element, weg damit:
    structureList.shift()
    Vue.set(state.mediaFilterList[1], 'parameter', structureList)
  },
  'DEL_ITEM_FROM_BILDTYP_FILTER' (state, id) {
    const itemList = state.filterState.bildTyp.itemList
    itemList.splice(itemList.map(x => x.pk).indexOf(id), 1)
  },
  'DEL_ITEM_FROM_STRUCTURE_FILTER' (state, id) {
    const itemList = state.filterState.structure.itemList
    itemList.splice(itemList.map(x => x.pk).indexOf(id), 1)
  },
  'SET_STRUCTURE_FILTER' (state, valueList) {
    Vue.set(state.filterState.structure, 'itemList', valueList)
  },
  'SET_BILDTYP_FILTER' (state, valueList) {
    Vue.set(state.filterState.bildTyp, 'itemList', valueList)
  },
  'SET_ELEMENT_FILTER' (state, searchToken) {
    Vue.set(state.filterState.elementName, 'searchToken', searchToken)
    Vue.set(state.filterState.elementAltName, 'searchToken', searchToken)
  },
  'RESET' (state) {
    for (const key in initialState) {
      Vue.set(state, key, initialState[key])
    }
  }
}

const actions = {
  getStructureList ({ commit, state, rootState }, topic) {
    commit('START_UPDATE')
    const url = `${rootState.appState.apihost}/${topic}/structure/`
    return fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(stream => stream.json())
      .then(response => {
        commit('SET_STRUCTURE_LIST', response.results)
      }).catch(error => {
        console.log(`Error loading structure list: ${error}`)
      }).finally(() => {
        commit('UPDATE_DONE')
      })
  }
}
export default {
  state,
  actions,
  getters,
  mutations
}
