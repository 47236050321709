<template>
<v-expansion-panel>
  <v-expansion-panel-header>
    <v-row class="spacer" align="center" >
      <v-col cols="4" sm="3" md="2" class="pb-0">
        <v-badge :color="score.primary + score.secondary > 0.5 ? 'success': 'error'" right overlap>
          <template v-slot:badge>
            <span class="font-weight-black">{{ score.primary + score.secondary }}</span>
          </template>
          <v-avatar size="64px">
            <img :src="getMediaUrl()">
          </v-avatar>
        </v-badge>
      </v-col>
      <v-col>
        {{ i+1 }}. {{ q.questionObject.question_text }}
        <v-btn
          text
          icon
          color="blue lighten-2"
          @click="$router.push({ name: 'simulation', params: { index: i+1 }})"
          >
          <v-icon large >
            link
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-expansion-panel-header>
  <v-expansion-panel-content eager >
    <div>
      <v-container>
        <v-row align="center">
          <v-col cols="12" sm="8">
            <h5>
              a) {{ q.questionObject.question_text }}
            </h5>
          </v-col>
          <v-col class="my-0 py-0" cols="12" sm="4">
            <v-row align="end">
              <v-col class="my-0 py-0" cols="12" md="6">
                <h5>
                  Punkte: {{ q.score.primary }}
                </h5>
              </v-col>
              <v-col v-if="q.questionObject.typ === 'Text'"
                     class="my-0 py-0"
                     cols="12" md="6"
                     >
                <h5>
                  Anzahl geforderter Antworten: {{ q.questionObject.correct_answers_needed }}
                </h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <ResultTextAnswer
        v-if="q.questionObject.typ === 'Text'"
        :question-object="q.questionObject"
        :answer-given="q.givenPrimaryAnswerText"
        :index="i"
        rank="primary"
        />
      <ResultMultipleChoiceAnswer
        v-else-if="q.questionObject.typ === 'Multiple choice'"
        :presented-option-list="q.presentedPrimaryMcOptions"
        :answer-given-list="q.givenPrimaryAnswerMC"
        :index="i"
        rank="primary"
        />
    </div>
    <div v-if="q.questionObject.secondary_questions.length > 0" class="my-3 pb-3">
      <v-container>
        <v-row align="center">
          <v-col cols="12" sm="8">
            <h5>
              b) {{ q.questionObject.secondary_questions[0].question_text }}
            </h5>
          </v-col>
          <v-col class="my-0 py-0" cols="12" sm="4">
            <v-row align="end">
              <v-col class="my-0 py-0" cols="12">
                <h5>
                  Punkte: {{ q.score.secondary }}
                </h5>
              </v-col>
              <v-col v-if="q.questionObject.secondary_questions[0].typ === 'Text'"
                     class="my-0 py-0"
                     cols="12" md="6"
                     >
                <h5>
                  Anzahl gerforderte Antworten: {{ q.questionObject.secondary_questions[0].correct_answers_needed }}
                </h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <ResultTextAnswer
        v-if="q.questionObject.secondary_questions[0].typ === 'Text'"
        :question-object="q.questionObject.secondary_questions[0]"
        :answer-given="q.givenSecondaryAnswerText"
        :index="i"
        rank="secondary"
        />
      <ResultMultipleChoiceAnswer
        v-else-if="q.questionObject.secondary_questions[0].typ === 'Multiple choice'"
        :presented-option-list="q.presentedSecondaryMcOptions"
        :answer-given-list="q.givenSecondaryAnswerMC"
        :index="i"
        rank="secondary"
        />
    </div>
    <div>
      <v-btn
        small
        @click.stop="$store.commit('TOGGLE_FEEDBACK_FORM', setAnswerReviewMessage())"
        >
        <v-icon>report</v-icon>
        <span>Fehler gefunden?</span>
      </v-btn>
    </div>
    <Feedback />
  </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import ResultMultipleChoiceAnswer from './ResultMultipleChoiceAnswer.vue'
import ResultTextAnswer from './ResultTextAnswer.vue'
import Feedback from './Feedback.vue'
export default {
  components: {
    ResultMultipleChoiceAnswer,
    ResultTextAnswer,
    Feedback
  },
  props: {
    q: {
      type: Object,
      default () { return {} }
    },
    i: {
      type: Number,
      default () { return 0 }
    }
  },
  computed: {
    score () {
      return {
        primary: isNaN(this.q.score.primary) ? 0 : this.q.score.primary,
        secondary: isNaN(this.q.score.secondary) ? 0 : this.q.score.secondary
      }
    }
  },
  methods: {
    setBackendUrl () {
      return `${this.$store.getters.apihost}/adminapp/question/${this.q.questionObject.id}`
    },
    setAnswerReviewMessage () {
      let prim = `Primäre Frage: ${this.setBackendUrl()}`
      let sec = `Sekundäre Frage: ${this.$store.getters.apihost}/adminapp/question/${this.q.questionObject.secondary_questions[0].id}`
      let host = window.location.host
      let protocol = window.location.protocol
      let json = JSON.stringify(this.q)
      let encodedJson = encodeURIComponent(json)
      let url = `${protocol}//${host}/#/reviewanswer?answer=${encodedJson}`
      return `${prim}\n${sec}\n\n${url}`
    },
    getMediaUrl () {
      let relativeUrl = this.q.questionObject.media[0].url
      return `${this.$store.getters.apihost}${relativeUrl}`
    }
  }
}
</script>
