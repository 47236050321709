var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        [
          _c(
            "v-row",
            { staticClass: "spacer", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "4", sm: "3", md: "2" } },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color:
                          _vm.score.primary + _vm.score.secondary > 0.5
                            ? "success"
                            : "error",
                        right: "",
                        overlap: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "badge",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "font-weight-black" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.score.primary + _vm.score.secondary
                                  )
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c("v-avatar", { attrs: { size: "64px" } }, [
                        _c("img", { attrs: { src: _vm.getMediaUrl() } })
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.i + 1) +
                      ". " +
                      _vm._s(_vm.q.questionObject.question_text) +
                      "\n        "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", icon: "", color: "blue lighten-2" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "simulation",
                            params: { index: _vm.i + 1 }
                          })
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("\n            link\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel-content",
        { attrs: { eager: "" } },
        [
          _c(
            "div",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "8" } }, [
                        _c("h5", [
                          _vm._v(
                            "\n              a) " +
                              _vm._s(_vm.q.questionObject.question_text) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "my-0 py-0",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "end" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "my-0 py-0",
                                  attrs: { cols: "12", md: "6" }
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      "\n                  Punkte: " +
                                        _vm._s(_vm.q.score.primary) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.q.questionObject.typ === "Text"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 py-0",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          "\n                  Anzahl geforderter Antworten: " +
                                            _vm._s(
                                              _vm.q.questionObject
                                                .correct_answers_needed
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.q.questionObject.typ === "Text"
                ? _c("ResultTextAnswer", {
                    attrs: {
                      "question-object": _vm.q.questionObject,
                      "answer-given": _vm.q.givenPrimaryAnswerText,
                      index: _vm.i,
                      rank: "primary"
                    }
                  })
                : _vm.q.questionObject.typ === "Multiple choice"
                ? _c("ResultMultipleChoiceAnswer", {
                    attrs: {
                      "presented-option-list": _vm.q.presentedPrimaryMcOptions,
                      "answer-given-list": _vm.q.givenPrimaryAnswerMC,
                      index: _vm.i,
                      rank: "primary"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.q.questionObject.secondary_questions.length > 0
            ? _c(
                "div",
                { staticClass: "my-3 pb-3" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "8" } }, [
                            _c("h5", [
                              _vm._v(
                                "\n              b) " +
                                  _vm._s(
                                    _vm.q.questionObject.secondary_questions[0]
                                      .question_text
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "my-0 py-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "end" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          "\n                  Punkte: " +
                                            _vm._s(_vm.q.score.secondary) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.q.questionObject.secondary_questions[0]
                                    .typ === "Text"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              "\n                  Anzahl gerforderte Antworten: " +
                                                _vm._s(
                                                  _vm.q.questionObject
                                                    .secondary_questions[0]
                                                    .correct_answers_needed
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.q.questionObject.secondary_questions[0].typ === "Text"
                    ? _c("ResultTextAnswer", {
                        attrs: {
                          "question-object":
                            _vm.q.questionObject.secondary_questions[0],
                          "answer-given": _vm.q.givenSecondaryAnswerText,
                          index: _vm.i,
                          rank: "secondary"
                        }
                      })
                    : _vm.q.questionObject.secondary_questions[0].typ ===
                      "Multiple choice"
                    ? _c("ResultMultipleChoiceAnswer", {
                        attrs: {
                          "presented-option-list":
                            _vm.q.presentedSecondaryMcOptions,
                          "answer-given-list": _vm.q.givenSecondaryAnswerMC,
                          index: _vm.i,
                          rank: "secondary"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.$store.commit(
                        "TOGGLE_FEEDBACK_FORM",
                        _vm.setAnswerReviewMessage()
                      )
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("report")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Fehler gefunden?")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("Feedback")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }