<template>
  <v-bottom-navigation
    :input-value="$vuetify.breakpoint.mdAndDown"
    app
    fixed
  >
    <v-btn @click.native="skip(-total/10)">
      <v-icon>
        fast_rewind
      </v-icon>
    </v-btn>
    <v-btn @click.native="skip(-1)">
      <v-icon>
        skip_previous
      </v-icon>
    </v-btn>
    <v-chip
      color="primary darken-2"
      text-color="white"
      style="flex: 0 1 auto"
    >
      {{ indexCurrent }}/{{ total }}
    </v-chip>
    <v-btn @click.native="skip(1)">
      <v-icon>
        skip_next
      </v-icon>
    </v-btn>
    <v-btn @click.native="skip(total/10)">
      <v-icon>
        fast_forward
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  computed: {
    indexCurrent () { return this.$route.params.index },
    total () {
      return this.$store.getters.filteredMediaList(
        this.$store.getters.mediaWithPinList
      ).length
    }
  },
  methods: {
    skip (stepSize) {
      const index = Math.min(
        Math.max(Math.round(Number(this.indexCurrent) + stepSize), 1),
        this.total
      )
      this.$router.push({
        name: this.$route.name,
        params: {
          index: index
        }
      })
    }
  }
}
</script>
