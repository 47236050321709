<template>
  <v-list-item
    :ref="element.element.name"
  >
    <v-chip
      color="primary darken-2"
      text-color="white"
    >
      {{ labelnr }}
    </v-chip>
    <span class="ml-2">
      {{ element.element.name_med }}
    </span>
  </v-list-item>
</template>
<script>
export default {
  props: {
    element: {
      type: Object,
      default () { return {} }
    },
    labelnr: {
      type: Number,
      default () { return 1 }
    }
  }
}
</script>
