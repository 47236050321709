import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import BottomNavigator from '../components/BottomNavigator.vue'
import BottomNavigatorExtender from '../components/BottomNavigatorExtender.vue'
import SimulationContent from '../components/SimulationContent.vue'
import SimulationStatus from '../components/SimulationStatus.vue'
import Cockpit from '../components/Cockpit.vue'
import CockpitContainerExtender from '../components/CockpitContainerExtender.vue'
import AnschauenRightDrawer from '../components/AnschauenRightDrawer.vue'
import MemorierenRightDrawer from '../components/MemorierenRightDrawer.vue'
import MainContainer from '../components/MainContainer.vue'
import MediaCard from '../components/MediaCard.vue'
import ElementList from '../components/ElementList.vue'
import MediaTraveller from '../components/MediaTraveller.vue'
import ChallengeTraveller from '../components/ChallengeTraveller.vue'
import ChallengeStatusNavigation from '../components/ChallengeStatusNavigation.vue'
import MemorizeHelp from '../components/MemorizeHelp.vue'
import MemorizeLabel from '../components/MemorizeLabel.vue'
import QuestionCard from '../components/QuestionCard.vue'
import RightDrawer from '../components/RightDrawer.vue'
import ReviewAnswer from '../components/ReviewAnswer.vue'
import ToolBar from '../components/ToolBar.vue'
import UniBalken from '../components/UniBalken.vue'
import ViewLabel from '../components/ViewLabel.vue'
import Welcome from '../components/Welcome.vue'
import AnswerComponent from '../components/AnswerComponent.vue'
import RouteContainer from '../components/RouteContainer.vue'
// import TimeMeter from '../components/TimeMeter.vue'

// sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

const router = new Router({
  routes: [{
    path: '/',
    name: 'welcome',
    components: {
      default: Welcome,
      metabalken: UniBalken,
      cockpit: false,
      rightDrawer: false
    },
    props: {
      default: {
        topicList: [{
          showName: 'Bewegungsapparat',
          name: 'bewegungsapparat',
          imgUrl: '/static/img/Gelenke_cubiti_lateral_ext_resized.jpg',
          bildTypListe: ['Schnitt', 'Foto', 'Radiologie']
        }, {
          showName: 'Topografische Anatomie',
          name: 'topographische',
          imgUrl: '/static/img/IMG_9172_resized.jpg',
          bildTypListe: ['Schnitt', 'Foto', 'Radiologie']
        }, {
          showName: 'Neuroanatomie',
          name: 'neuro',
          imgUrl: '/static/img/Gehirn-horizontal-1.jpg',
          bildTypListe: ['Schnitt', 'Foto', 'Radiologie']
        }]
      }
    }
  }, {
    path: '/reviewanswer',
    name: 'ReviewAnswer',
    components: {
      default: ReviewAnswer,
      metabalken: false,
      cockpit: false,
      rightDrawer: false
    },
    props: {
      default: route => ({ answer: route.query.answer })
    }
  }, {
    path: '/element',
    name: 'ElementList',
    components: {
      default: ElementList,
      metabalken: ToolBar,
      cockpit: false,
      rightDrawer: false
    }
  }, {
    path: '/:topic',
    components: {
      default: MainContainer,
      metabalken: ToolBar,
      cockpit: Cockpit,
      rightDrawer: RightDrawer,
      bottomNavigation: BottomNavigatorExtender
    },
    children: [{
      path: '',
      redirect: '/',
      name: 'test'
    }, {
      path: 'simulation',
      components: {
        mainContent: SimulationContent,
        rightDrawerContent: RouteContainer,
        cockpitContainer: CockpitContainerExtender
      },
      beforeEnter: (to, from, next) => {
        // console.log('Entering Simulation Parent')
        next()
      },
      children: [{
        path: '',
        name: 'simulation-status',
        components: {
          challengeContent: SimulationStatus,
          cockpitContainer: ChallengeStatusNavigation
        },
        props: {
          cockpitContainer: { challengeMode: 'simulation' },
          challengeContent: { challengeMode: 'simulation' }
        },
        beforeEnter: (to, from, next) => {
          // console.log('Entering Simulation Status')
          next()
        }
      }, {
        path: ':index',
        name: 'simulation',
        components: {
          challengeContent: QuestionCard,
          routeContainer: AnswerComponent,
          cockpitContainer: ChallengeTraveller
        },
        props: {
          cockpitContainer: { challengeMode: 'simulation' },
          challengeContent: { hideLabeling: true, challengeMode: 'simulation' },
          routeContainer: { challengeMode: 'simulation' }
        },
        beforeEnter: (to, from, next) => {
          // console.log('Entering Simulation Index')
          next()
        }
      }]
    }, {
      path: 'anschauen',
      components: {
        rightDrawerContent: AnschauenRightDrawer,
        mainContent: MediaCard,
        cockpitContainer: MediaTraveller,
        bottomNavigationExtender: BottomNavigator
      },
      children: [{
        path: '',
        redirect: '1'
      }, {
        name: 'anschauen',
        path: ':index',
        components: {
          elementLabel: ViewLabel
        }
      }]
    }, {
      path: 'memorieren',
      components: {
        mainContent: MediaCard,
        rightDrawerContent: MemorierenRightDrawer,
        cockpitContainer: MediaTraveller,
        bottomNavigationExtender: BottomNavigator,
        help: MemorizeHelp
      },
      children: [{
        path: '',
        redirect: '1'
      }, {
        name: 'memorieren',
        path: ':index',
        components: {
          elementLabel: MemorizeLabel
        }
      }]
    }]
  }]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'ElementList') {
    Promise.all(
      [
        store.dispatch('getList', {
          url: '/element',
          name: 'elementList'
        })
      ]
    ).then(() => next())
  } else if (to.params.topic && to.params.topic !== from.params.topic) {
    Promise.all([
      store.dispatch('getList', {
        url: `/${to.params.topic}/media`,
        name: 'mediaList'
      }),
      store.dispatch('getList', {
        url: `/${to.params.topic}/elementmedia`,
        name: 'pinList'
      }),
      store.dispatch('getStructureList', to.params.topic)
    ]).then(() => {
      store.commit('SET_MEDIA_WITH_PINS')
      const filteredMediaList = store.getters.filteredMediaList(
        store.getters.mediaWithPinList
      ).sort((a, b) => a.name.localeCompare(b.name))
      if (to.params.index && filteredMediaList.length > 0) {
        const mediaItem = filteredMediaList[to.params.index - 1]
        const pinList = store.getters.pinList.filter(
          (el) => el.media === Number(mediaItem.id)
        ).sort((a, b) => a.no - b.no)
        // we to control the visibility from another component via vuex store:
        store.commit('ADD_PINLABEL_VISIBILITY',
          pinList.map((pin) => {
            pin.isVisible = false
            return pin
          })
        )
      }
      next()
    })
  } else if (to.params.topic && to.params.index) {
    const filteredMediaList = store.getters.filteredMediaList(
      store.getters.mediaWithPinList
    )
    const mediaItem = filteredMediaList[to.params.index - 1]
    const pinList = store.getters.pinList.filter(
      (el) => el.media === Number(mediaItem.id)
    ).sort((a, b) => a.no - b.no)
    // we to control the visibility from another component via vuex store:
    store.commit('ADD_PINLABEL_VISIBILITY',
      pinList.map((pin) => {
        pin.isVisible = false
        return pin
      })
    )
    next()
  } else next()
})

Sentry.init({
  Vue,
  dsn: 'https://3055f959303e42919aacd2ae87bacf4a@o398207.ingest.sentry.io/6003412',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'tutorial.anatomie.unibas.ch', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

Vue.use(Router)

export default router
