<template>
  <div>
    <v-navigation-drawer
      v-model="showCockpit"
      :temporary="$vuetify.breakpoint[$store.getters.cockpitTemporarySize]"
      clipped
      app
    >
      <v-list class="pa-1">
        <v-list-item tag="div">
          <v-list-item-avatar>
            <v-icon>explore</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Navigation</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="$vuetify.breakpoint.mdAndDown">
            <v-btn
              icon
              @click.stop="() => $store.commit('SET_COCKPIT_STATE', false)"
            >
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <router-view name="cockpitContainer" />
      <v-list dense>
        <v-list-item
          @click.stop="$router.push('/')"
        >
          <v-list-item-action>
            <v-icon>cancel</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Zurück zur Startseite</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- reset data, do we need this?
        <v-list-item @click.stop="$router.push('/'); $store.commit('RESET');" >
          <v-list-item-action>
            <v-icon>stop</v-icon>
          </v-list-item-action>
        </v-list-item>
        -->
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      v-model="cacheDeletionInProgress"
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card tile>
        <v-card-title>
          <span class="headline">Cache wird gelöscht</span>
        </v-card-title>
        <v-card-text>
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cacheDeletionInProgress: false
    }
  },
  computed: {
    showCockpit: {
      set: function (value) { this.$store.commit('SET_COCKPIT_STATE', value) },
      get: function () { return this.$store.getters.showCockpit }
    }
  },
  created () { // hide on small size
    this.$store.commit('SET_COCKPIT_STATE', this.$vuetify.breakpoint.lgAndUp)
  }
}

</script>
