var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        color: "primary",
        "clipped-left": "",
        "clipped-right": ""
      }
    },
    [
      _vm.$vuetify.breakpoint.mdAndDown
        ? _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$store.commit("SET_COCKPIT_STATE", true)
                }
              }
            },
            [_c("v-icon", [_vm._v("explore")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-toolbar-title", [_vm._v("Online Tutorial Anatomie ")]),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$store.commit("SET_RIGHT_DRAWER_STATE", true)
                }
              }
            },
            [_c("v-icon", [_vm._v("list")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }