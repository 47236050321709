import Vue from 'vue'

const initialState = {
  notifications: []
}

const state = Vue.util.extend({}, initialState)

const getters = {
  notifications: state => state.notifications
}

const actions = {
  notify ({ commit, state }, message) {
    message.severity = message.severity || 'info'

    // Show errors in notifications only on local environment
    // if (message.severity === 'error' && !Vue.config.productionTip) {
    //   return // Don't log errors to the user anymore
    // }

    // commit(types.ADD_NOTIFICATION, message)
    // setTimeout(function () {
    //   return commit(types.REMOVE_NOTIFICATION)
    // }, 8000)
  }
}

const mutations = {
  'RESET' (state) {
    for (const key in initialState) {
      Vue.set(state, key, initialState[key])
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
