var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0" },
    [
      _c(
        "v-row",
        _vm._l(_vm.evaluateOptions(), function(o, i) {
          return _c(
            "v-col",
            {
              key: i,
              staticClass: "py-0 my-0",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("v-checkbox", {
                attrs: {
                  label: o.text,
                  error: !o.correct,
                  success: o.correct,
                  readonly: "",
                  "py-0": "",
                  "my-0": "",
                  "hide-details": "",
                  light: ""
                },
                model: {
                  value: o.checked,
                  callback: function($$v) {
                    _vm.$set(o, "checked", $$v)
                  },
                  expression: "o.checked"
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }