<template>
  <v-row>
    <v-col>
      <v-container class="justify" fluid>
        <v-row justify="center">
          <TopicCard
            v-for="topic in topicList"
            :key="topic.name"
            :topic="topic"
          />
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import TopicCard from './TopicCard.vue'
export default {
  components: {
    TopicCard
  },
  props: {
    topicList: {
      type: Array,
      default () { return [] }
    }
  }
}
</script>
