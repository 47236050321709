var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-center" }, [
        _vm.challengeIsComplete
          ? _c("h5", [
              _vm._v(
                "\n      Du hast bereits alle Fragen beantwortet.\n      "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "#/" + _vm.$route.params.topic + "/simulation"
                  }
                },
                [_vm._v("\n        Zurück zur Resultatseite gehen.\n      ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h3", [_vm._v("Frage " + _vm._s(_vm.index))])
      ]),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "text-center pt-10" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 270,
                size: 100,
                width: 15,
                value: _vm.percentage,
                color: _vm.timerColor
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.challObj.score.questionList[_vm.index - 1].timeLeft
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary darken-2", "text-color": "white" },
              nativeOn: {
                click: function($event) {
                  return _vm.cancelChallenge.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n      Abbrechen\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "primary darken-2", "text-color": "white" },
              nativeOn: {
                click: function($event) {
                  return _vm.nextStep()
                }
              }
            },
            [_vm._v("\n      Weiter\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }